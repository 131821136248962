import axios from "axios";

import { API_BASE_URL } from "../config";

export const apiRequest = async (method, path, body, params, responseType) => {
    const token = localStorage.getItem("token");

    if (!token) {
        window.location.hash = "/";

        return;
    }

    const response = await axios({
        url: `${API_BASE_URL}${path}`,
        method: method,
        timeout: 0,
        headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
        },
        data: body,
        responseType: responseType,
        params: params,
    });

    if (response.status === 401) {
        window.location.hash = "/";

        return;
    }

    return response;
};
