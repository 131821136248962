import React from "react";

import Select from "react-select";

import { useTranslation } from "react-i18next";

import { useBibliotecaContext } from "../../context/BibliotecaContext";

export function SegmentosFilter() {
    const { t } = useTranslation();

    const { segmentosData, segmentosSelecionados, setSegmentosSelecionados } = useBibliotecaContext();

    const options = segmentosData
        ? segmentosData.map((segmento) => ({
            label: segmento.n2 ? `${segmento.n1} - ${segmento.n2}` : segmento.n1,
            value: segmento.id,
        }))
        : [];

    const selectedOptions = options.filter((option) =>
        segmentosSelecionados.includes(option.value)
    );

    const handleChange = (selectedOptions) => {
        setSegmentosSelecionados(
            selectedOptions ? selectedOptions.map((option) => option.value) : []
        );
    };

    return (
        <div className="mt-4">
            <div className="mb-2">{t("biblioteca.areaOfActivity")}</div>

            <Select
                value={selectedOptions}
                isMulti
                name="segmentos"
                options={options}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={handleChange}
                placeholder={t("biblioteca.selectArea")}
                noOptionsMessage={() => {
                    t("biblioteca.emptyArea");
                }}
            />
        </div>
    );
}
