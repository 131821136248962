let API_BASE_URL;
let SISTEMA_URL;

const AMBIENTE = 'PROD';

switch (AMBIENTE) {
    case 'PROD':
        API_BASE_URL = 'https://cliente-api.taxupdate.com.br';
        SISTEMA_URL = 'https://www.taxupdate.co';
        break;
    case 'HOMOLOGACAO':
        API_BASE_URL = 'https://homologacao.cliente-api.taxupdate.com.br';
        SISTEMA_URL = 'https://homologacao.taxupdate.co';
        break;
    default:
        API_BASE_URL = 'http://localhost:8000';
        SISTEMA_URL = 'http://taxupdate.local';
        break;
}

export { API_BASE_URL, SISTEMA_URL };