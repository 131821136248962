import dayjs from "dayjs";

import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";

import { GoArrowSwitch } from "react-icons/go";

import { apiRequest } from "../../api/apiRequest";

import Loading from "../Loading";

import { useDashboardContext } from "../../context/DashboardContext";

function Conversor() {
    const { t } = useTranslation();

    const {
        error, setError,
        valorBid, setValorBid,
        valor, setValor,
        de, setDe,
        para, setPara,
        createDate, setCreateDate
    } = useDashboardContext();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await apiRequest(
                    "GET",
                    `/conversor/${valor}/${de}/${para}`
                );

                setValorBid(response.data.valor_bid);
                setCreateDate(response.data.create_date);
            } catch (error) {
                setValorBid(0.0);
                setError(error);
            }
        };

        fetchData();
    }, [valor, de, para]);

    if (error) return <p className="mx-3">{t("dashboard.errorConversor")}</p>;

    return (
        <div className="ml-4">
            <div
                className="border-0 shadow-sm bg-white py-3"
                style={{ borderRadius: "1rem" }}
            >
                <div className="px-3">
                    <h3>{t("dashboard.converter")}</h3>
                </div>

                {valorBid ? (
                    <div className="px-3">
                        <h2
                            style={{ fontSize: "27px", fontWeight: "600" }}
                            id="resultConversor"
                        >
                            $ {valorBid}
                        </h2>

                        <form className="form-horizontal">
                            <label
                                className="mb-1 control-label text-black-50"
                                style={{ fontSize: "11px" }}
                            >
                                {t("dashboard.value")}
                            </label>

                            <input
                                className="form-control"
                                type="number"
                                value={valor}
                                onChange={(e) => setValor(e.target.value || 0)}
                                style={{ borderRadius: "0.5rem" }}
                            />

                            <div className="d-flex justify-content-between align-items-end mt-3">
                                <div>
                                    <label
                                        className="mb-1 control-label text-black-50"
                                        style={{ fontSize: "11px" }}
                                    >
                                        {t("dashboard.from")}
                                    </label>

                                    <select
                                        className="form-control"
                                        style={{ borderRadius: "0.5rem" }}
                                        value={de}
                                        onChange={(e) => setDe(e.target.value)}
                                    >
                                        <option value="USD">USD</option>
                                        <option value="BRL">BRL</option>
                                        <option value="ARS">ARS</option>
                                        <option value="AUD">AUD</option>
                                        <option value="CAD">CAD</option>
                                        <option value="CHF">CHF</option>
                                        <option value="CNY">CNY</option>
                                        <option value="EUR">EUR</option>
                                        <option value="GBP">GBP</option>
                                        <option value="HKD">HKD</option>
                                        <option value="INR">INR</option>
                                        <option value="JPY">JPY</option>
                                        <option value="KRW">KRW</option>
                                        <option value="MXN">MXN</option>
                                        <option value="NOK">NOK</option>
                                        <option value="NZD">NZD</option>
                                        <option value="SEK">SEK</option>
                                        <option value="RUB">RUB</option>
                                        <option value="SGD">SGD</option>
                                        <option value="TRY">TRY</option>
                                        <option value="ZAR">ZAR</option>
                                    </select>
                                </div>

                                <button
                                    className="btn btn-link"
                                    style={{ fontSize: "20px" }}
                                    onClick={() => {
                                        setDe(para);
                                        setPara(de);
                                    }}
                                >
                                    <GoArrowSwitch />
                                </button>

                                <div>
                                    <label
                                        className="mb-1 control-label text-black-50"
                                        style={{ fontSize: "11px" }}
                                    >
                                        {t("dashboard.to")}
                                    </label>

                                    <select
                                        className="form-control"
                                        style={{ borderRadius: "0.5rem" }}
                                        value={para}
                                        onChange={(e) => setPara(e.target.value)}
                                    >
                                        <option value="USD">USD</option>
                                        <option value="BRL">BRL</option>
                                        <option value="ARS">ARS</option>
                                        <option value="AUD">AUD</option>
                                        <option value="CAD">CAD</option>
                                        <option value="CHF">CHF</option>
                                        <option value="CNY">CNY</option>
                                        <option value="EUR">EUR</option>
                                        <option value="GBP">GBP</option>
                                        <option value="HKD">HKD</option>
                                        <option value="INR">INR</option>
                                        <option value="JPY">JPY</option>
                                        <option value="KRW">KRW</option>
                                        <option value="MXN">MXN</option>
                                        <option value="NOK">NOK</option>
                                        <option value="NZD">NZD</option>
                                        <option value="SEK">SEK</option>
                                        <option value="RUB">RUB</option>
                                        <option value="SGD">SGD</option>
                                        <option value="TRY">TRY</option>
                                        <option value="ZAR">ZAR</option>
                                    </select>
                                </div>
                            </div>

                            <div className="mt-3 mb-4">
                                <div className="text-black-50" style={{ fontSize: "11px" }}>
                                    {dayjs(createDate)
                                        .utc(false)
                                        .format("D [de] MMM., HH:mm:ss [UTC]")}
                                </div>

                                <div
                                    className="text-black-50"
                                    style={{
                                        fontSize: "11px",
                                    }}
                                >
                                    {t("dashboard.disclaimer")}
                                </div>
                            </div>
                        </form>
                    </div>
                ) : (
                    <Loading />
                )}
            </div>
        </div>
    );
}
export default Conversor;
