import React, { createContext, useState, useContext } from 'react';

const InformativoContext = createContext();

export const useInformativoContext = () => {
    return useContext(InformativoContext);
};

export const InformativoProvider = ({ children }) => {

    const formatDate = (date) => {
        return date.toISOString().split("T")[0];
    };

    const currentDate = new Date();
    const startDate = new Date();

    startDate.setFullYear(currentDate.getFullYear() - 1);

    const [dataDe, setDataDe] = useState(formatDate(startDate));
    const [dataAte, setDataAte] = useState(formatDate(currentDate));
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [hasMore, setHasMore] = useState(true);

    return (
        <InformativoContext.Provider value={
            {
                dataDe, setDataDe,
                dataAte, setDataAte,
                data, setData,
                loading, setLoading,
                error, setError,
                page, setPage,
                itemsPerPage, setItemsPerPage,
                hasMore, setHasMore
            }
        }>
            {children}
        </InformativoContext.Provider>
    );
};
