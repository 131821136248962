import React from "react";

import { useTranslation } from "react-i18next";

export function StatisticalDataTable({ title, data }) {
    const { t } = useTranslation();

    return (
        <table className="table table-borderless" style={{ width: "60%" }}>
            <thead className="bg-light">
                <tr>
                    <th
                        className="pl-3 font-weight-light"
                        style={{ textAlign: "center" }}
                    >
                        {title}
                    </th>

                    <th className="font-weight-light" style={{ textAlign: "center" }}>
                        {t("dadosEstatisticos.published")}
                    </th>

                    <th className="font-weight-light" style={{ textAlign: "center" }}>
                        {t("dadosEstatisticos.reviewed")}
                    </th>

                    <th className="font-weight-light" style={{ textAlign: "center" }}>
                        {t("dadosEstatisticos.forwarded")}
                    </th>
                </tr>
            </thead>

            <tbody>
                {data ? (
                    data.map((item, index) => (
                        <tr key={item.id || index}>
                            <td
                                className="pl-3 font-weight-light"
                                style={{ textAlign: "center" }}
                            >
                                {item.date}
                            </td>

                            <td className="font-weight-light" style={{ textAlign: "center" }}>
                                {item.publicado}
                            </td>

                            <td className="font-weight-light" style={{ textAlign: "center" }}>
                                {item.analisado}
                            </td>

                            <td className="font-weight-light" style={{ textAlign: "center" }}>
                                {item.encaminhado}
                            </td>
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td colSpan="4" className="text-center">
                            {t("dadosEstatisticos.errorMessage")}
                        </td>
                    </tr>
                )}
            </tbody>

        </table>
    );
}
