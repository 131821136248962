import React from "react";

import { useTranslation } from "react-i18next";

import { useBibliotecaContext } from "../../context/BibliotecaContext";

export function NumberLawFilter() {
    const { t } = useTranslation();

    const { numeroDaLei, setNumeroDaLei } = useBibliotecaContext();

    const formatarValor = (val) => {
        if (val) {
            return val
                .replace(/\D/g, '')
                .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        }

        return '';
    };

    const handleChange = (event) => {
        const formattedValue = formatarValor(event.target.value);

        setNumeroDaLei(formattedValue);
    };

    return (
        <div className="mt-4 mb-3">
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <input
                    type="text"
                    name="numeroLei"
                    className="form-control rounded-lg"
                    placeholder={t("biblioteca.lawNumber")}
                    value={numeroDaLei}
                    onChange={handleChange}
                    style={{
                        height: "40px",
                        flex: 1,
                    }}
                />
            </div>
        </div>

    );
}
