import React, { createContext, useState, useContext } from 'react';

const ResetPasswordContext = createContext();

export const useResetPasswordContext = () => {
    return useContext(ResetPasswordContext);
};

export const ResetPasswordProvider = ({ children }) => {

    const [email, setEmail] = useState("");
    const [errors, setErrors] = useState({});
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    return (
        <ResetPasswordContext.Provider value={
            {
                email, setEmail,
                errors, setErrors,
                success, setSuccess,
                loading, setLoading
            }
        }>
            {children}
        </ResetPasswordContext.Provider>
    );
};
