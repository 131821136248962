import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import React from "react";

import { useTranslation } from "react-i18next";

import Banner from "../components/Dashboard/Banner";
import BibliotecaDeNormas from "../components/Dashboard/BibliotecaDeNormas";
import CalendariosFiscais from "../components/Dashboard/CalendariosFiscais";
import Conversor from "../components/Dashboard/Conversor";
import DadosEstatisticos from "../components/Dashboard/DadosEstatisticos";
import PostsHome from "../components/Dashboard/Posts";
import ProximaData from "../components/Dashboard/ProximaData";
import UltimosReports from "../components/Dashboard/UltimosReports";
import Navbar from "../components/Navbar";
import SideMenu from "../components/SideMenu";

import { useDashboardContext } from "../context/DashboardContext";

dayjs.extend(utc);

function Dashboard() {
    const { t } = useTranslation();

    const { selectedTable, setSelectedTable } = useDashboardContext();

    const year = new Date().getFullYear();

    const handleChangeTable = (e) => {
        setSelectedTable(e.target.value);

        localStorage.setItem("table-option", e.target.value);
    };

    return (
        <div id="wrapper" style={{ backgroundColor: "#043A5E" }}>
            <SideMenu />

            <div id="page-wrapper" style={{ backgroundColor: "#FCFCFD" }}>
                <Navbar />

                <div
                    className="mt-4"
                    style={{
                        fontSize: "38px",
                        fontWeight: "600",
                        padding: "0 1rem 0 1rem",
                        marginBottom: "1rem",
                    }}
                >
                    {t("dashboard.title")} {year}
                </div>

                <div
                    className="wrapper wrapper-content"
                    style={{ padding: " 0 1rem 0 1rem" }}
                >
                    <div className="d-flex justify-content-between mb-4">
                        <div style={{ width: "68%" }}>
                            <DadosEstatisticos />
                        </div>

                        <div style={{ width: "32%" }}>
                            <ProximaData />
                        </div>
                    </div>

                    <div className="d-flex">
                        <div style={{ width: "68%" }}>
                            {/* Seletor das Tabelas*/}
                            <div
                                className="border-0 shadow-sm bg-white"
                                style={{ borderRadius: "1rem" }}
                            >
                                <div className="d-flex py-3 pl-2 justify-content-between align-items-center">
                                    <div style={{ width: "40%" }}>
                                        <select
                                            className="form-control border-0 font-weight-bold bg-light"
                                            style={{ borderRadius: "0.5rem" }}
                                            value={selectedTable}
                                            onChange={handleChangeTable}
                                        >
                                            <option
                                                selected={
                                                    selectedTable === "biblioteca"
                                                        ? "selected"
                                                        : undefined
                                                }
                                                value="biblioteca"
                                            >
                                                {t("dashboard.standardsLibrary")}
                                            </option>

                                            <option
                                                selected={
                                                    selectedTable === "reports" ? "selected" : undefined
                                                }
                                                value="reports"
                                            >
                                                {t("dashboard.latestReports")}
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                {/* {table} */}
                                {selectedTable === "biblioteca" ? (
                                    <BibliotecaDeNormas />
                                ) : (
                                    <UltimosReports />
                                )}
                            </div>
                        </div>

                        <div style={{ width: "32%" }}>
                            <CalendariosFiscais />
                        </div>
                    </div>

                    <div className="d-flex">
                        <div style={{ width: "68%" }}>
                            <Banner />

                            <PostsHome />
                        </div>

                        <div style={{ width: "32%" }}>
                            <Conversor />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
