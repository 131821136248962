import React, { createContext, useState, useContext } from 'react';

const DashboardContext = createContext();

export const useDashboardContext = () => {
    return useContext(DashboardContext);
};

export const DashboardProvider = ({ children }) => {

    const tableOption = localStorage.getItem("table-option");
    const [selectedTable, setSelectedTable] = useState(tableOption || "reports");
    const [normasData, setNormasData] = useState("");
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [calendarData, setCalendarData] = useState(null);
    const [valorBid, setValorBid] = useState("");
    const [valor, setValor] = useState(100);
    const [de, setDe] = useState("USD");
    const [para, setPara] = useState("BRL");
    const [createDate, setCreateDate] = useState("");
    const [staticDatas, setStaticDatas] = useState(null);
    const [postsData, setPostsData] = useState(null);
    const [nextEventPage, setNextEventPage] = useState(1);
    const [nextEventData, setNextEventData] = useState(null);
    const [enableButton, setEnableButton] = useState(true);
    const [normasReportsData, setNormasReportsData] = useState(null);

    return (
        <DashboardContext.Provider value={
            {
                selectedTable, setSelectedTable,
                normasData, setNormasData,
                error, setError,
                loading, setLoading,
                calendarData, setCalendarData,
                valorBid, setValorBid,
                valor, setValor,
                de, setDe,
                para, setPara,
                createDate, setCreateDate,
                staticDatas, setStaticDatas,
                postsData, setPostsData,
                nextEventPage, setNextEventPage,
                nextEventData, setNextEventData,
                enableButton, setEnableButton,
                normasReportsData, setNormasReportsData
            }
        }>
            {children}
        </DashboardContext.Provider>
    );
};
