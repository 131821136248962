function Loading() {
    return (
        <div
            className="d-flex justify-content-center align-items-center"
            style={{ paddingBottom: "20px" }}
        >
            <div
                className="spinner-border "
                style={{
                    width: "2rem",
                    height: "2rem",
                    color: "#6C737F",
                    paddingBottom: "5px",
                }}
                role="status"
            ></div>
        </div>
    );
}

export default Loading;
