import dayjs from "dayjs";

import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";

import { AiOutlineExclamationCircle } from "react-icons/ai";

import { apiRequest } from "../../api/apiRequest";

import Loading from "../Loading";

import { useDashboardContext } from "../../context/DashboardContext";

function ProximaData() {
    const { t } = useTranslation();

    const {
        nextEventPage, setNextEventPage,
        nextEventData, setNextEventData,
        enableButton, setEnableButton,
        error, setError,
        loading, setLoading
    } = useDashboardContext();

    const mesAtual = dayjs().format("MM-DD");

    const formatDate = (dateString) => {
        const [datePart] = dateString.split(" ");
        const [year, month, day] = datePart.split("-");

        return `${day}/${month}/${year}`;
    };

    useEffect(() => {
        const fetchNextEvent = async () => {
            try {
                const nextEventResponse = await apiRequest(
                    "GET",
                    `/dashboard/calendario?mes=${mesAtual}&limite_page=1&pagina=${nextEventPage}&desde_hoje=true`
                );

                if (nextEventResponse.data.length) {
                    setNextEventData(nextEventResponse.data[0]);
                } else {
                    setEnableButton(false);
                }
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchNextEvent();
    }, [nextEventPage]);

    if (loading) {
        return <Loading />;
    }

    if (error) return <p className="mx-3">{t("dashboard.errorNextDate")}</p>;

    return (
        <div className="ml-4">
            <div className="">
                <button
                    type="button"
                    className="btn btn-link"
                    onClick={() => setNextEventPage(nextEventPage + 1)}
                    disabled={!enableButton ? "disabled" : undefined}
                    style={{ padding: "0" }}
                >
                    <h3>{t("dashboard.nextDate")}</h3>
                </button>
            </div>

            <div
                className="p-3 bg-white border-0 shadow-sm text-center"
                style={{ borderRadius: "1rem", minHeight: "120px" }}
            >
                <div className="d-flex flex-column align-items-start">
                    {nextEventData && (
                        <div className="d-flex justify-content-between align-items-center text-black-50 w-100">
                            <div style={{ textAlign: "left" }}>
                                {nextEventData ? nextEventData.title : ""}
                            </div>

                            {nextEventData && (
                                <button
                                    type="button"
                                    className="btn btn-link"
                                    data-toggle="tooltip"
                                    data-placement="bottom"
                                    title={t("dashboard.tooltip")}
                                >
                                    <div style={{ fontSize: "20px", lineHeight: "0px" }}>
                                        <AiOutlineExclamationCircle />
                                    </div>
                                </button>
                            )}
                        </div>
                    )}

                    <h2 className="mt-1" style={{ fontSize: "18px", fontWeight: "600" }}>
                        {nextEventData
                            ? formatDate(nextEventData.start)
                            : t("dashboard.emptyNextDate")}
                    </h2>
                </div>
            </div>
        </div>
    );
}

export default ProximaData;
