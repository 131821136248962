import React, { createContext, useState, useContext } from 'react';

const LeiturasTecnicasContext = createContext();

export const useLeiturasTecnicasContext = () => {
    return useContext(LeiturasTecnicasContext);
};

export const LeiturasTecnicasProvider = ({ children }) => {

    const [activeMenu, setActiveMenu] = useState("todos");
    const [postsData, setPostsData] = useState({});
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [hasMore, setHasMore] = useState(true);

    return (
        <LeiturasTecnicasContext.Provider value={
            {
                activeMenu, setActiveMenu,
                postsData, setPostsData,
                error, setError,
                loading, setLoading,
                page, setPage,
                itemsPerPage, setItemsPerPage,
                hasMore, setHasMore
            }
        }>
            {children}
        </LeiturasTecnicasContext.Provider>
    );
};
