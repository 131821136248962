import axios from "axios";

import React, { useEffect, useState } from "react";

import { useSearchParams } from 'react-router-dom';

import { useNavigate } from "react-router-dom";

import { API_BASE_URL } from "../config";

import detalhe from "../assets/img/login/detalhe.png";
import logo from "../assets/img/logo-taxupdate.png";

import { useResetPasswordContext } from "../context/ResetPasswordContext";

import { FaEyeSlash, FaEye } from "react-icons/fa";

function ResetPassword() {
    const {
        email, setEmail,
        errors, setErrors,
        success, setSuccess,
        loading, setLoading
    } = useResetPasswordContext();

    const [searchParams] = useSearchParams();
    const [emailUrl, setEmailUrl] = useState('');
    const [token, setToken] = useState('');
    const [isSolicitaTrocaDeSenha, setIsSolicitaTrocaDeSenha] = useState(true);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const emailParam = searchParams.get('email');
        const tokenParam = searchParams.get('token');

        if (emailParam && tokenParam) {
            setEmailUrl(emailParam);
            setToken(tokenParam);

            axios
                .post(`${API_BASE_URL}/contas/reset-password/validate`, {
                    email: emailParam,
                    token: tokenParam,
                })
                .then((response) => {
                    setLoading(false);
                    // ele ja solicitou e quer redefinir
                    setIsSolicitaTrocaDeSenha(false);
                })
                .catch((error) => {
                    setLoading(false);
                    setIsSolicitaTrocaDeSenha(true);
                });
        }
    }, [searchParams]);

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        return re.test(email);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const newErrors = {};

        if (!email) {
            newErrors.email = "O campo e-mail é obrigatório";
        } else if (!validateEmail(email)) {
            newErrors.email = "O e-mail precisa ser válido";
        }

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);

            return;
        }

        setLoading(true);

        axios
            .post(`${API_BASE_URL}/contas/reset-password`, { email })
            .then((response) => {
                setSuccess(true);
                setErrors({});
                setLoading(false);
                setTimeout(() => {
                    navigate("/login");
                }, 2000);
            })
            .catch((error) => {
                console.error(error);

                setLoading(false);

                const newErrors = {};

                if (error.response) {
                    const serverErrors = error.response.data;

                    newErrors.email =
                        serverErrors.message || "Ocorreu um erro ao enviar o e-mail.";

                    setErrors(newErrors);
                } else if (error.request) {
                    newErrors.email = "Sem resposta do servidor.";

                    setErrors(newErrors);
                } else {
                    newErrors.email = "Erro ao configurar a requisição.";

                    setErrors(newErrors);
                }
            });
    };

    const handleSubmitNewPassword = (e) => {
        e.preventDefault();

        if (newPassword !== confirmPassword) {
            setErrors({
                newPassword: "As senhas devem ser iguais.",
                confirmPassword: "As senhas devem ser iguais.",
            });
        } else {
            setErrors({});
            setLoading(true);

            axios
                .post(`${API_BASE_URL}/contas/reset-password/reset`, {
                    email: emailUrl,
                    token,
                    senha: newPassword,
                })
                .then((response) => {
                    setSuccess(true);
                    setErrors({});
                    setLoading(false);
                    setTimeout(() => {
                        navigate("/login");
                    }, 2000);
                })
                .catch((error) => {
                    console.error(error);

                    setLoading(false);

                    const newErrors = {};

                    if (error.response) {
                        const serverErrors = error.response.data;

                        newErrors.newPassword =
                            serverErrors.message || "Ocorreu um erro ao redefinir a senha.";

                        setErrors(newErrors);
                    } else if (error.request) {
                        newErrors.newPassword = "Sem resposta do servidor.";

                        setErrors(newErrors);
                    } else {
                        newErrors.newPassword = "Erro ao configurar a requisição.";

                        setErrors(newErrors);
                    }
                });
        }
    };

    return (
        <div className="">
            <div className="d-flex" style={{ height: "100vh" }}>
                <div
                    className="d-flex flex-column align-items-center justify-content-center"
                    style={{
                        width: "60%",
                        backgroundColor: "#043A5E",
                        backgroundImage: `url(${detalhe})`,
                        backgroundPosition: "330px center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        position: "relative",
                    }}
                >
                    <img
                        src={logo}
                        alt="Imagem decorativa"
                        style={{
                            position: "absolute",
                            top: "5%",
                            left: "10%",
                            width: "124px",
                            height: "auto",
                        }}
                    />

                    <div style={{ width: "80%" }}>
                        <h2
                            className=""
                            style={{ fontSize: "38px", fontWeight: "600", color: "#429A85" }}
                        >
                            Bem Vindo a TaxUpdate!
                        </h2>

                        <div
                            className="text-white"
                            style={{
                                fontSize: "13px",
                                fontWeight: "500",
                                lineHeight: "1.5rem",
                            }}
                        >
                            Simplificamos o acompanhamento da legislação tributária do Brasil
                            <br />e mais de 30 países da América Latina.
                        </div>

                        <div
                            className="text-white mt-4 mb-3"
                            style={{ fontSize: "13px", fontWeight: "500" }}
                        >
                            Junte-se a empresas inovadoras
                        </div>

                        {/* 
                        <div className="d-flex align-items-center">
                            <div className="mr-4">
                                <img
                                    src={samsung}
                                    alt="SAMSUNG"
                                    style={{ width: "105px", height: "auto" }}
                                />
                            </div>

                            <div className="mr-4">
                                <img
                                    src={visma}
                                    alt="VISMA"
                                    style={{ width: "90px", height: "auto" }}
                                />
                            </div>

                            <div className="mr-4">
                                <img
                                    src={bolt}
                                    alt="Bolt"
                                    style={{ width: "38px", height: "auto" }}
                                />
                            </div>

                            <div className="mr-4">
                                <img
                                    src={aws}
                                    alt="AWS"
                                    style={{ width: "40px", height: "auto" }}
                                />
                            </div>

                            <div className="mr-4">
                                <img
                                    src={accenture}
                                    alt="accenture"
                                    style={{ width: "92px", height: "auto" }}
                                />
                            </div>

                            <div className="mr-4">
                                <img
                                    src={atat}
                                    alt="AT&T"
                                    style={{ width: "58px", height: "auto" }}
                                />
                            </div>
                        </div> */}

                    </div>
                </div>

                {isSolicitaTrocaDeSenha ? (
                    <div
                        className="d-flex flex-column align-items-center justify-content-center bg-white"
                        style={{ width: "40%" }}
                    >
                        <div
                            className="custom-normal text-start w-100 pl-5 mb-4"
                            style={{ fontSize: "22px", fontWeight: "600" }}
                        >
                            Recupere sua senha
                        </div>

                        <form
                            className="px-5"
                            role="form"
                            onSubmit={handleSubmit}
                            style={{ width: "100%" }}
                        >
                            <div className="form-group">
                                <input
                                    type="email"
                                    className="form-control"
                                    placeholder="E-mail"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    style={{
                                        borderRadius: "7px",
                                        borderColor: errors.email
                                            ? "#F14437"
                                            : success
                                                ? "#84BE8C"
                                                : "",
                                        borderWidth: errors.email ? "2px" : "1px",
                                    }}
                                />

                                {errors.email && (
                                    <small style={{ color: "#F14437" }}>{errors.email}</small>
                                )}

                                {success && (
                                    <small style={{ color: "green" }}>
                                        O e-mail foi enviado com sucesso
                                    </small>
                                )}
                            </div>

                            <button
                                type="submit"
                                className="btn btn-primary block full-width m-b"
                                style={{ borderRadius: "7px" }}
                                disabled={loading}
                            >
                                {loading ? "Enviando..." : "Continuar"}
                            </button>

                            <a
                                href="/#"
                                className="btn btn-light block full-width"
                                role="button"
                            >
                                Voltar
                            </a>
                        </form>
                    </div>
                ) : (
                    <div
                        className="d-flex flex-column align-items-center justify-content-center bg-white"
                        style={{ width: "40%" }}
                    >
                        <div
                            className="custom-normal text-start w-100 pl-5 mb-4"
                            style={{ fontSize: "22px", fontWeight: "600" }}
                        >
                            Redefinir sua senha
                        </div>

                        <form
                            className="px-5"
                            role="form"
                            onSubmit={handleSubmitNewPassword}
                            style={{ width: "100%" }}
                        >
                            <div className="form-group" style={{ position: "relative", width: "100%" }}>
                                <input
                                    type={showPassword ? "text" : "password"}
                                    className="form-control"
                                    placeholder="Nova senha"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    autoComplete="off"
                                    style={{
                                        borderRadius: "7px",
                                        borderColor: errors.newPassword
                                            ? "#F14437"
                                            : success
                                                ? "#84BE8C"
                                                : "",
                                        borderWidth: errors.newPassword ? "2px" : "1px",
                                        paddingRight: "40px",
                                    }}
                                />
                                <button
                                    type="button"
                                    onClick={() => setShowPassword(!showPassword)}
                                    style={{
                                        position: "absolute",
                                        right: "10px",
                                        top: "50%",
                                        transform: "translateY(-50%)",
                                        background: "none",
                                        border: "none",
                                        cursor: "pointer",
                                        padding: "0",
                                    }}
                                >
                                    {showPassword ? <FaEye size={20} style={{ color: "#3A3A3A" }} /> : <FaEyeSlash size={20} style={{ color: "#3A3A3A" }} />}
                                </button>
                            </div>

                            <div className="form-group" style={{ position: "relative", width: "100%" }}>
                                <input
                                    type={showConfirmPassword ? "text" : "password"}
                                    className="form-control"
                                    placeholder="Repita a nova senha"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    autoComplete="off"
                                    style={{
                                        borderRadius: "7px",
                                        borderColor: errors.confirmPassword
                                            ? "#F14437"
                                            : success
                                                ? "#84BE8C"
                                                : "",
                                        borderWidth: errors.confirmPassword ? "2px" : "1px",
                                        paddingRight: "40px",
                                    }}
                                />
                                <button
                                    type="button"
                                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                    style={{
                                        position: "absolute",
                                        right: "10px",
                                        top: "50%",
                                        transform: "translateY(-50%)",
                                        background: "none",
                                        border: "none",
                                        cursor: "pointer",
                                        padding: "0",
                                    }}
                                >
                                    {showConfirmPassword ? <FaEye size={20} style={{ color: "#3A3A3A" }} /> : <FaEyeSlash size={20} style={{ color: "#3A3A3A" }} />}
                                </button>


                            </div>
                            {errors.confirmPassword && (
                                <small style={{ color: "#F14437" }}>{errors.confirmPassword}</small>
                            )}

                            {success && (
                                <small style={{ color: "green" }}>
                                    Senha redefinida com sucesso
                                </small>
                            )}

                            <button
                                type="submit"
                                className="btn btn-primary block full-width m-b mt-3"
                                style={{ borderRadius: "7px" }}
                                disabled={loading}
                            >
                                {loading ? "Enviando..." : "Continuar"}
                            </button>

                            <a
                                href="/#"
                                className="btn btn-light block full-width"
                                role="button"
                            >
                                Voltar
                            </a>
                        </form>
                    </div>
                )}
            </div>
        </div>
    );
}

export default ResetPassword;
