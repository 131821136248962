import React, { createContext, useState, useContext } from 'react';

const LoginContext = createContext();

export const useLoginContext = () => {
    return useContext(LoginContext);
};

export const LoginProvider = ({ children }) => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({ email: false, password: false });

    return (
        <LoginContext.Provider value={
            {
                email, setEmail,
                password, setPassword,
                errors, setErrors
            }
        }>
            {children}
        </LoginContext.Provider>
    );
};
