import React from "react";

import Select from "react-select";

import { useTranslation } from "react-i18next";

import { useBibliotecaContext } from "../../context/BibliotecaContext";

export function StateFilter() {
    const { t } = useTranslation();

    const { estadosData, estadosSelecionados, setEstadosSelecionados } = useBibliotecaContext();

    const options = estadosData
        ? estadosData.map((estado) => ({
            label: `${estado.nome} - ${estado.descricao}`,
            value: estado.areaatuacao_id,
        }))
        : [];

    const selectedOptions = options.filter((option) =>
        estadosSelecionados.includes(option.value)
    );

    const handleChange = (selectedOptions) => {
        setEstadosSelecionados(
            selectedOptions ? selectedOptions.map((option) => option.value) : []
        );
    };

    return (
        <div className="mt-4">
            <div className="mb-2">{t("biblioteca.state")}</div>

            <Select
                value={selectedOptions}
                isMulti
                name="estados"
                options={options}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={handleChange}
                placeholder={t("biblioteca.selectState")}
                noOptionsMessage={() => {
                    t("biblioteca.emptyState");
                }}
            />
        </div>
    );
}
