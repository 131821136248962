import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { useParams } from "react-router-dom";

import { apiRequest } from "../../api/apiRequest";

import Loading from "../Loading";
import Navbar from "../Navbar";
import SideMenu from "../SideMenu";

import { InternaDaNorma } from "./InternaDaNorma";

export function InternalScreen() {
    const { t } = useTranslation();

    const { path, id } = useParams();

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            let requestPath = path;

            if (path === "norma") {
                requestPath = "dashboard/norma";
            }

            try {
                const response = await apiRequest("GET", `/${requestPath}/${id}`);

                if (path === "apresentacao") {
                    setData(response.data.newsletter.conteudo);
                } else if (path === "norma") {
                    setData(response.data.norma);
                }
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    if (loading) {
        return <Loading />;
    }

    if (error) return <p className="mx-3"> {t("interna.errorMessage")}</p>;

    return (
        <div id="wrapper" style={{ backgroundColor: "#043A5E" }}>
            <SideMenu />

            <div
                id="page-wrapper"
                className="pb-5"
                style={{ backgroundColor: "#FCFCFD" }}
            >
                <Navbar />

                <div
                    className="bg-white shadow-sm d-flex flex-column justify-content-between"
                    style={{ borderRadius: "1rem" }}
                >
                    {path === "apresentacao" && data && (
                        <div dangerouslySetInnerHTML={{ __html: data }} />
                    )}
                    {path === "norma" && data && <InternaDaNorma data={data} />}
                </div>
            </div>
        </div>
    );
}
