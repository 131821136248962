import dayjs from "dayjs";

import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";

import { IoIosArrowRoundForward } from "react-icons/io";

import { Link } from "react-router-dom";

import { apiRequest } from "../../api/apiRequest";

import Loading from "../Loading";

import { useDashboardContext } from "../../context/DashboardContext";

function CalendariosFiscais() {
    const { t } = useTranslation();

    const {
        calendarData, setCalendarData,
        error, setError
    } = useDashboardContext();

    const mesAtual = dayjs().format("MM-DD");

    const formatDate = (dateString) => {
        const [datePart] = dateString.split(" ");
        const [year, month, day] = datePart.split("-");
        return `${day}/${month}/${year}`;
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const calendarResponse = await apiRequest(
                    "GET",
                    `/dashboard/calendario?mes=${mesAtual}&limite_page=6&pagina=1&desde_hoje=true`
                );

                setCalendarData(calendarResponse.data);
            } catch (error) {
                setError(error);
            }
        };

        fetchData();
    }, []);

    if (error) return <p className="mx-3">{t("dashboard.errorCalendar")}</p>;

    return (
        <div
            className="border-0 shadow-sm bg-white ml-4"
            style={{ borderRadius: "1rem" }}
        >
            <div className="">
                <div className="p-3">
                    <h3>{t("dashboard.fiscalCalendars")}</h3>
                </div>

                {calendarData ? (
                    <div className="">
                        <table className="table table-borderless">
                            <thead className="bg-light">
                                <tr>
                                    <th
                                        className="pl-3 font-weight-light"
                                        style={{ width: "65%" }}
                                    >
                                        {t("dashboard.tableCalendar.event")}
                                    </th>

                                    <th className="font-weight-light">
                                        {t("dashboard.tableCalendar.date")}
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                {calendarData ? (
                                    calendarData.map((item) => (
                                        <tr key={item.id}>
                                            <td className="pl-3 font-weight-light">{item.title}</td>
                                            <td className="font-weight-light">{formatDate(item.start)}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="3" className="text-center">
                                            {t("dashboard.noDatesFound")}
                                        </td>
                                    </tr>
                                )}
                            </tbody>

                            <tfoot className="pl-4 mt-2">
                                <tr>
                                    <td colSpan="3">
                                        <div style={{
                                            marginBottom: "1rem",
                                            display: "flex"
                                        }}>
                                            <Link
                                                to={"/calendario"}
                                                style={{
                                                    color: "inherit",
                                                    textDecoration: "none"
                                                }}
                                            >
                                                {t("dashboard.seeMore")}
                                            </Link>

                                            <div
                                                className="ml-1 d-flex justify-content-start align-items-center"
                                                style={{
                                                    fontSize: "1.3rem"
                                                }}
                                            >
                                                <IoIosArrowRoundForward />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                ) : (
                    <Loading />
                )}
            </div>
        </div>
    );
}

export default CalendariosFiscais;
