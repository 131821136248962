import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { FaLinkedin } from "react-icons/fa";
import { TbWorld } from "react-icons/tb";
import Select from "react-select";
import headerOne from "../assets/img/header_one.png";
import headerTwo from "../assets/img/header_two.png";
import headerThree from "../assets/img/header_three.png";
import "../assets/css/trial.css";
import { API_BASE_URL } from '../config';
import { motion, AnimatePresence } from "framer-motion";

const IMAGES = [headerOne, headerTwo, headerThree];

const validateEmailComercial = (emailComercial) => {
    if (!emailComercial) return false;

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Remove espaços extras, separa por ',' ou ';', e filtra e-mails vazios
    const emails = emailComercial
        .split(/[,;]/)
        .map(email => email.trim())
        .filter(email => email !== ""); // Remove strings vazias

    return emails.length > 0 && emails.every(email => emailRegex.test(email));
};

const Trial = ({ encryptedData }) => {
    const [cnpjValid, setCnpjValid] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [navbarBg, setNavbarBg] = useState('bg-transparent');
    const [navbarColor, setNavbarColor] = useState('text-black');
    const [currentImage, setCurrentImage] = useState(0);
    const [fade, setFade] = useState(true);
    const [grupoAtual, setGrupoAtual] = useState(1);
    const [passoAtual, setPassoAtual] = useState(1);
    const inputRefEmail = useRef(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isMessageOpen, setIsMessageOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [areaAtuacaoMunicipal, setAreaAtuacaoMunicipal] = useState([]);
    const [areaAtuacaoEstadual, setAreaAtuacaoEstadual] = useState([]);
    const [areaAtuacaoFederal, setAreaAtuacaoFederal] = useState([]);
    const [areaAtuacaoSubTributaria, setAreaAtuacaoSubTributaria] = useState([]);
    const [allAreasAtuacao, setAllAreasAtuacao] = useState([]);
    const [formData, setFormData] = useState({
        dadosDaEmpresa: {
            cnpj: "",
            razaoSocial: "",
            endereco: "",
            telefone: "",
            responsavel: "",
            emailComercial: "",
        },
        dadosDaAreaDeAtuacao: {
            areaAtuacaoF: [],
            areaAtuacaoE: [],
            areaAtuacaoM: []
        },
        regras: {
            acompanhamentoNacional: false,
            estadosAcompanhados: []
        },
        informacoesExtras: {
            ncms: "",
            cests: "",
            marcasAssociadas: "",
            informacoesSobreAEmpresa: "",
            palavrasChaveParaAcompanhamento: ""
        }
    });

    const navigate = useNavigate();

    const totalGrupos = 4;

    const optionsM = areaAtuacaoMunicipal.map((item) => ({
        label: item.nome,
        id: item.id,
        value: item.nome,
        ativo: item.ativo,
    }));

    const optionsE = areaAtuacaoEstadual.map((item) => ({
        label: item.nome,
        id: item.id,
        value: item.nome,
        ativo: item.ativo,
    }));

    const optionsF = areaAtuacaoFederal.map((item) => ({
        label: item.nome,
        id: item.id,
        value: item.nome,
        ativo: item.ativo,
    }));

    const optionsSubTributaria = areaAtuacaoSubTributaria.map((item) => ({
        label: item.nome,
        id: item.id,
        value: item.nome,
        ativo: item.ativo,
    }));

    const selectedOptionsAreaDeAtuacaoF = optionsF.filter((option) =>
        formData.dadosDaAreaDeAtuacao.areaAtuacaoF.some((federal) => federal.nome === option.value)
    );

    const selectedOptionsAreaDeAtuacaoE = optionsE.filter((option) =>
        formData.dadosDaAreaDeAtuacao.areaAtuacaoE.some((estado) => estado.nome === option.value)
    );

    const selectedOptionsAreaDeAtuacaoM = optionsM.filter((option) =>
        formData.dadosDaAreaDeAtuacao.areaAtuacaoM.some((municipio) => municipio.nome === option.value)
    );

    const selectedOptionsEstadosAcompanhados = optionsSubTributaria.filter((option) =>
        formData.regras.estadosAcompanhados.some((estado) => estado.nome === option.value)
    );

    const proximoGrupo = useCallback(() => {
        if (grupoAtual < totalGrupos) {
            setGrupoAtual(grupoAtual + 1);

            setPassoAtual(1);
        }
    }, [grupoAtual]);

    useEffect(() => {
        const validateCNPJ = async () => {
            if (!encryptedData) {
                setCnpjValid(false);
                setIsLoading(false);
                return;
            }

            try {
                const response = await axios.post(`${API_BASE_URL}/validate-empresa`, { encryptedData });
                setCnpjValid(response.data.exists);
            } catch (error) {
                console.error("Erro ao validar CNPJ:", error);
                setCnpjValid(false);
            } finally {
                setIsLoading(false);
            }
        };

        const fetchDataM = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/search-area-atuacao-municipal`);

                setAreaAtuacaoMunicipal(response.data.areas_atuacao);
            } catch (error) {
                console.error(error);
            }
        }

        const fetchDataE = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/search-area-atuacao-estadual`);

                setAreaAtuacaoEstadual(response.data.areas_atuacao);
            } catch (error) {
                console.error(error);
            }
        }

        const fetchDataF = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/search-area-atuacao-federal`);

                setAreaAtuacaoFederal(response.data.areas_atuacao);
            } catch (error) {
                console.error(error);
            }
        }

        const fetchDataSubTributaria = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/search-area-atuacao-subtributaria`);

                setAreaAtuacaoSubTributaria(response.data.areas_atuacao);
            } catch (error) {
                console.error(error);
            }
        }

        validateCNPJ();
        fetchDataM();
        fetchDataE();
        fetchDataF();
        fetchDataSubTributaria();
    }, [encryptedData]);

    useEffect(() => {
        const areasAtuacaoCombinadas = [
            ...areaAtuacaoMunicipal,
            ...areaAtuacaoEstadual,
            ...areaAtuacaoFederal
        ];

        setAllAreasAtuacao(areasAtuacaoCombinadas);
    }, [areaAtuacaoMunicipal, areaAtuacaoEstadual, areaAtuacaoFederal]);

    useEffect(() => {
        const interval = setInterval(() => {
            setFade(false);

            setTimeout(() => {
                setCurrentImage((prev) => (prev + 1) % IMAGES.length);

                setFade(true);
            }, 250);
        }, 20000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            const heroSection = document.getElementById('hero');

            if (heroSection) {
                const heroHeight = heroSection.offsetHeight;

                const scrollPosition = window.scrollY;

                if (scrollPosition > heroHeight) {
                    setNavbarBg('bg-white shadow');

                    setNavbarColor('#676a6c');
                } else {
                    setNavbarBg('bg-transparent');

                    setNavbarColor('white');
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        if (inputRefEmail.current) {
            inputRefEmail.current.style.height = "40px";

            inputRefEmail.current.style.height = `${inputRefEmail.current.scrollHeight}px`;
        }
    }, [formData.dadosDaEmpresa.emailComercial, passoAtual]);

    useEffect(() => {
        if (isModalOpen || isMessageOpen) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }
    }, [isModalOpen, isMessageOpen]);

    const handleChangeAreaDeAtuacaoF = (selectedOptions) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            dadosDaAreaDeAtuacao: {
                ...prevFormData.dadosDaAreaDeAtuacao,
                areaAtuacaoF: selectedOptions
                    ? selectedOptions.map((option) => ({
                        id: option.id,
                        nome: option.value,
                        ativo: option.ativo,
                    }))
                    : [],
            },
        }));
    };

    const handleChangeAreaDeAtuacaoE = (selectedOptions) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            dadosDaAreaDeAtuacao: {
                ...prevFormData.dadosDaAreaDeAtuacao,
                areaAtuacaoE: selectedOptions
                    ? selectedOptions.map((option) => ({
                        id: option.id,
                        nome: option.value,
                        ativo: option.ativo,
                    }))
                    : [],
            },
        }));
    };

    const handleChangeAreaDeAtuacaoM = (selectedOptions) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            dadosDaAreaDeAtuacao: {
                ...prevFormData.dadosDaAreaDeAtuacao,
                areaAtuacaoM: selectedOptions
                    ? selectedOptions.map((option) => ({
                        id: option.id,
                        nome: option.value,
                        ativo: option.ativo,
                    }))
                    : [],
            },
        }));
    };

    const handleChangeEstadosAcompanhados = (selectedOptions) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            regras: {
                ...prevFormData.regras,
                estadosAcompanhados: selectedOptions
                    ? selectedOptions.map((option) => ({
                        id: option.id,
                        nome: option.value,
                        ativo: option.ativo,
                    }))
                    : [],
            },
        }));
    };

    const handleScrollToSection = useCallback((id) => {
        const element = document.getElementById(id);

        if (element) element.scrollIntoView({ behavior: 'smooth' });
    }, []);

    const handleImageChange = useCallback((index) => {
        setFade(false);

        setTimeout(() => {
            setCurrentImage(index);

            setFade(true);
        }, 250);
    }, []);

    const handleChange = useCallback((e) => {
        const { name, value, dataset: { group } } = e.target;

        setFormData(prevData => ({
            ...prevData,
            [group]: { ...prevData[group], [name]: value }
        }));
    }, []);

    const handleStepClick = useCallback((e) => {
        const group = e.target.dataset.group;

        if (group) {
            const newGrupo = parseInt(group.split('grupo')[1]);

            if (newGrupo && newGrupo >= 1 && newGrupo <= totalGrupos) {
                setGrupoAtual(newGrupo);

                setPassoAtual(1);
            }
        }
    }, [totalGrupos]);

    const handleSubmit = useCallback((e) => {
        e.preventDefault();

        setIsModalOpen(true);

        const selecionados = [
            ...formData.dadosDaAreaDeAtuacao.areaAtuacaoF,
            ...formData.dadosDaAreaDeAtuacao.areaAtuacaoE,
            ...formData.dadosDaAreaDeAtuacao.areaAtuacaoM
        ];

        if (selecionados.length > 0) {
            let ativos = [];
            let inativos = [];

            selecionados.forEach((id) => {
                const local = allAreasAtuacao.find(l => l.id === id.id);

                if (local) {
                    local.ativo ? ativos.push(local.nome) : inativos.push(local.nome);
                }
            });

            let mensagem = "Atenção! ⚠️\n\n";

            if (ativos.length === 0) {
                mensagem += `Nenhum dos locais selecionados '${inativos.join(", ")}' está disponível para o trial.\n\n` +
                    "Todos os locais selecionados serão avaliados futuramente.";
            } else if (inativos.length === 0) {
                setIsMessageOpen(false);

                return;
            } else {
                mensagem += `Dos locais de acompanhamento federal, estadual e municipal selecionados, apenas '${ativos.join(", ")}' estão disponíveis para o trial.\n\n` +
                    `Os demais '${inativos.join(", ")}' serão avaliados futuramente.`;
            }

            setMessage(mensagem);
            setIsMessageOpen(true);
        }
    }, [formData, allAreasAtuacao]);

    const validateForm = () => {
        const { dadosDaEmpresa, dadosDaAreaDeAtuacao, regras, informacoesExtras } = formData;

        switch (true) {
            case !dadosDaEmpresa.cnpj.trim():
                return "O CNPJ da empresa é obrigatório.";
            case !dadosDaEmpresa.razaoSocial.trim():
                return "A razão social da empresa é obrigatória.";
            case !dadosDaEmpresa.endereco.trim():
                return "O endereço da empresa é obrigatório.";
            case !dadosDaEmpresa.emailComercial.trim():
                return "O e-mail comercial da empresa é obrigatório.";
            case !validateEmailComercial(dadosDaEmpresa.emailComercial):
                return "Existem e-mails inválidos na lista de E-mail comercial.";

            case dadosDaAreaDeAtuacao.areaAtuacaoF.length === 0:
                return "Selecione pelo menos um local de acompanhamento federal.";
            case dadosDaAreaDeAtuacao.areaAtuacaoE.length === 0:
                return "Selecione pelo menos um local de acompanhamento estadual.";
            case dadosDaAreaDeAtuacao.areaAtuacaoM.length === 0:
                return "Selecione pelo menos um local de acompanhamento municipal.";

            case regras.acompanhamentoNacional && regras.estadosAcompanhados.length === 0:
                return "Selecione pelo menos um estado acompanhado.";
            default:
                return null;
        }
    };

    const handleConfirm = useCallback(async () => {
        try {
            setIsModalOpen(false);

            // valida o formulário
            const errorMessage = validateForm();

            if (errorMessage) {
                setMessage(errorMessage + " ❌" || "Ocorreu um erro! Entre em contato com o nosso atendimento pelo e-mail tax.update@taxupdate.com.br. ❌");

                setIsMessageOpen(true);

                return;
            }

            // Prepara os dados dos locais de acompanhamento ativos e inativos
            const selecionados = [
                ...formData.dadosDaAreaDeAtuacao.areaAtuacaoF,
                ...formData.dadosDaAreaDeAtuacao.areaAtuacaoE,
                ...formData.dadosDaAreaDeAtuacao.areaAtuacaoM
            ];

            let ativosArray = [];
            let inativosArray = [];
            let substitutoTributarioArray = [];
            let ativos = "";
            let inativos = "";
            let substitutoTributario = "";

            if (selecionados.length > 0) {
                selecionados.forEach((id) => {
                    const local = Object.values(allAreasAtuacao).flatMap(arr => arr).find(l => l.id === id.id);

                    if (local) {
                        local.ativo ? ativosArray.push(local.nome) : inativosArray.push(local.nome);
                    }
                });

                ativos = ativosArray.join(",");
                inativos = inativosArray.join(",");
            }

            if (formData.regras.estadosAcompanhados.length > 0) {
                formData.regras.estadosAcompanhados.forEach((id) => {
                    const estado = Object.values(allAreasAtuacao).flatMap(arr => arr).find(e => e.id === id.id);

                    if (estado) {
                        substitutoTributarioArray.push(estado.nome);
                    }
                });

                substitutoTributario = substitutoTributarioArray.join(",");
            }

            const formatEmailComercial = (emailComercial) => {
                if (!emailComercial) return "";

                return emailComercial
                    .split(/[,;]/)
                    .map(email => email.trim())
                    .filter(email => email !== "")
                    .join(";");
            };

            formData.dadosDaEmpresa.emailComercial = formatEmailComercial(formData.dadosDaEmpresa.emailComercial);

            setIsLoading(true);

            // chama a API
            const response = await axios.post(`${API_BASE_URL}/save-empresa`, {
                encryptedData: encryptedData,
                cnpj: formData.dadosDaEmpresa.cnpj,
                razaoSocial: formData.dadosDaEmpresa.razaoSocial,
                endereco: formData.dadosDaEmpresa.endereco,
                contatoComercial: formData.dadosDaEmpresa.telefone,
                responsavelComercial: formData.dadosDaEmpresa.responsavel,
                emailsComerciais: formData.dadosDaEmpresa.emailComercial,
                locaisAcompanhamentoAtivo: ativos,
                substituicaoTributaria: substitutoTributario,
                flg_setup_brasil: 0,
                escopo: `<div><label>Locais de acompanhamento inativo:</label><br/><span>${inativos || "Nenhum"}<span/><br/><br/><label>NCMs:</label><br/><span>${formData.informacoesExtras.ncms || "Nenhum"}<span/><br/><br/><label>CESTs:</label><br/><span>${formData.informacoesExtras.cests || "Nenhum"}<span/><br/><br/><label>Marcas associadas:</label><br/><span>${formData.informacoesExtras.marcasAssociadas || "Nenhuma"}<span/><br/><br/><label>Sobre a empresa:</label><br/><span>${formData.informacoesExtras.informacoesSobreAEmpresa || "Nenhuma"}<span/><br/><br/><label>Palavras-chave:</label><br/><span>${formData.informacoesExtras.palavrasChaveParaAcompanhamento || "Nenhuma"}<span/></div>`,
            }, {
                headers: {
                    "Content-Type": "application/json"
                },
                withCredentials: true
            });

            setIsLoading(false);

            setMessage("Sua solicitação foi enviada com sucesso! Em breve entraremos em contato. ✅");

            setIsMessageOpen(true);

            // Redireciona para a tela principal
            setTimeout(() => {
                navigate("/");
            }, 3000);
        } catch (error) {
            setIsLoading(false);

            setMessage("Ocorreu um erro! Entre em contato com o nosso atendimento pelo e-mail tax.update@taxupdate.com.br. ❌");

            setIsMessageOpen(true);
        }
    }, [formData, allAreasAtuacao]);

    if (isLoading) {
        return (
            <div className="loader-wrapper">
                <div className="loader"></div>
            </div>
        );
    }

    if (cnpjValid === false) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh', backgroundColor: 'white' }}>
                <div className="text-center text-dark display-4">Este formulário está bloqueado para edição</div>
            </div>
        );
    }

    const renderDadosDaEmpresa = () => {
        const passos = [
            { numero: 1, label: "CNPJ da empresa", placeholder: "CNPJ somente números...", campo: "cnpj", type: "number" },
            { numero: 2, label: "Razão social da empresa", placeholder: "Razão social...", campo: "razaoSocial", type: "text" },
            { numero: 3, label: "Endereço da empresa", placeholder: "Endereço...", campo: "endereco", type: "text" },
            { numero: 4, label: "Contato comercial", placeholder: "Telefone somente números...", campo: "telefone", type: "number" },
            { numero: 5, label: "Responsável comercial", placeholder: "Responsável...", campo: "responsavel", type: "text" },
            { numero: 6, label: "Lista de e-mails comerciais. Caso houver mais de um, separar por virgula ou ponto e vírgula", campo: "emailComercial", type: "text" }
        ];

        return (
            <AnimatePresence>
                <motion.div
                    className="formulario-grupo"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.3 }}
                >
                    <h3 className="grupo-titulo">Dados da Empresa</h3>

                    <motion.div
                        className="campos-grupo"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3 }}
                    >
                        {passos.map(passo => (
                            <motion.div
                                key={passo.numero}
                                className="passo-item"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.3 }}
                            >
                                <label>{passo.label}</label>

                                {passo.campo === "emailComercial" ? (
                                    <motion.div
                                        className="form-group position-relative"
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: 0 }}
                                        transition={{ duration: 0.3 }}
                                    >
                                        <textarea
                                            ref={inputRefEmail}
                                            type={passo.type}
                                            name={passo.campo}
                                            data-group="dadosDaEmpresa"
                                            value={formData.dadosDaEmpresa[passo.campo]}
                                            onChange={handleChange}
                                            className="text_area"
                                            placeholder="Digite os e-mails..."
                                            required
                                        />
                                    </motion.div>
                                ) : (
                                    <motion.div
                                        className="form-group position-relative"
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: 0 }}
                                        transition={{ duration: 0.3 }}
                                    >
                                        <input
                                            type={passo.type}
                                            name={passo.campo}
                                            data-group="dadosDaEmpresa"
                                            value={formData.dadosDaEmpresa[passo.campo]}
                                            onChange={handleChange}
                                            className="input"
                                            placeholder={passo.placeholder}
                                            required
                                        />
                                    </motion.div>
                                )}
                            </motion.div>
                        ))}
                    </motion.div>
                </motion.div>
            </AnimatePresence>
        );
    };

    const renderAreasDeAtuacao = () => {
        const passos = [
            { numero: 1, label: "Selecione os locais de acompanhamento FEDERAL", campo: "areaAtuacaoF", handleChange: handleChangeAreaDeAtuacaoF, selectedOptions: selectedOptionsAreaDeAtuacaoF, options: optionsF },
            { numero: 2, label: "Selecione os locais de acompanhamento ESTADUAL", campo: "areaAtuacaoE", handleChange: handleChangeAreaDeAtuacaoE, selectedOptions: selectedOptionsAreaDeAtuacaoE, options: optionsE },
            { numero: 3, label: "Selecione os locais de acompanhamento MUNICIPAL", campo: "areaAtuacaoM", handleChange: handleChangeAreaDeAtuacaoM, selectedOptions: selectedOptionsAreaDeAtuacaoM, options: optionsM }
        ];

        return (
            <AnimatePresence>
                <motion.div
                    className="formulario-grupo"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.3 }}
                >
                    <h3 className="grupo-titulo">Dados de Escopo Geográfico</h3>

                    <motion.div
                        className="campos-grupo"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3 }}
                    >
                        {passos.map(passo => (
                            <motion.div
                                key={passo.numero}
                                className="passo-item"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.3 }}
                            >
                                <label>{passo.label}</label>

                                <motion.div
                                    className="form-group position-relative"
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    transition={{ duration: 0.3 }}
                                >
                                    <Select
                                        value={passo.selectedOptions}
                                        isMulti
                                        name="segmentos"
                                        options={passo.options}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        onChange={passo.handleChange}
                                        placeholder="Selecione..."
                                        noOptionsMessage={() => "Sem resultados"}
                                    />
                                </motion.div>
                            </motion.div>
                        ))}
                    </motion.div>
                </motion.div>
            </AnimatePresence>
        );
    };

    const renderRegrasENotas = () => {
        const passos = [
            { numero: 1, label: "Regras de negócio", campo: "regras" }
        ];

        return (
            <AnimatePresence>
                <motion.div
                    className="formulario-grupo"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.3 }}
                >
                    <h3 className="grupo-titulo">
                        {passos[0].label}
                    </h3>

                    <motion.div
                        className="campos-grupo"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3 }}
                    >
                        <motion.div
                            className="form-check"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.3 }}
                        >
                            <input
                                type="checkbox"
                                className="form-check-input mt-1"
                                id="acompanhamentoNacional"
                                checked={formData.regras.acompanhamentoNacional}
                                onChange={(e) => handleChange({
                                    target: {
                                        name: "acompanhamentoNacional",
                                        value: e.target.checked,
                                        dataset: { group: "regras" }
                                    }
                                })}
                            />

                            <label className="form-check-label" htmlFor="acompanhamentoNacional">
                                Há algum estado em que deseja apenas o acompanhamento legislativo para fins de substituição tributária?
                            </label>
                        </motion.div>

                        {formData.regras.acompanhamentoNacional && (
                            <motion.div
                                className="mt-3"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.3 }}
                            >
                                <label>Selecione estados acompanhados apenas para substituição tributaria</label>

                                <Select
                                    value={selectedOptionsEstadosAcompanhados}
                                    isMulti
                                    name="estadosAcompanhados"
                                    options={optionsSubTributaria}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={handleChangeEstadosAcompanhados}
                                    placeholder="Selecione..."
                                    noOptionsMessage={() => "Sem resultados"}
                                />
                            </motion.div>
                        )}
                    </motion.div>
                </motion.div>
            </AnimatePresence>
        );
    };

    const informacoesExtras = () => {
        const passos = [
            { numero: 1, label: "Quais NCMs deseja acompanhamento", placeholder: "NCMs...", campo: "ncms" },
            { numero: 2, label: "Quais CESTs deseja acompanhamento", placeholder: "CESTs...", campo: "cests" },
            { numero: 3, label: "Se houver marcas associadas quais são", placeholder: "Marcas associadas...", campo: "marcasAssociadas" },
            { numero: 4, label: "Nos conte um pouco mais sobre a empresa, escopo de atuação, número de filiais, etc", placeholder: "Informações sobre a empresa...", campo: "informacoesSobreAEmpresa" },
            { numero: 5, label: "Quais palavras chave acha relevante para o acompanhamento, ex: 'Reforma Tributária', 'ICMS', etc", placeholder: "Palavras-chave...", campo: "palavrasChaveParaAcompanhamento" }
        ];

        return (
            <AnimatePresence>
                <motion.div
                    className="formulario-grupo"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.3 }}
                >
                    <h3 className="grupo-titulo">Informações Extras</h3>

                    <motion.div
                        className="campos-grupo"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3 }}
                    >
                        {passos.map(passo => (
                            <motion.div
                                key={passo.numero}
                                className="passo-item"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.3 }}
                            >
                                <label>{passo.label}</label>

                                <motion.div
                                    className="form-group position-relative"
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    transition={{ duration: 0.3 }}
                                >
                                    <textarea
                                        type="text"
                                        name={passo.campo}
                                        data-group="informacoesExtras"
                                        value={formData.informacoesExtras[passo.campo]}
                                        onChange={handleChange}
                                        className="text_area"
                                        placeholder={passo.placeholder}
                                        required
                                    />
                                </motion.div>
                            </motion.div>
                        ))}
                    </motion.div>
                </motion.div>
            </AnimatePresence>
        );
    };

    const renderGrupoAtual = () => {
        switch (grupoAtual) {
            case 1:
                return renderDadosDaEmpresa();
            case 2:
                return renderAreasDeAtuacao();
            case 3:
                return renderRegrasENotas();
            case 4:
                return informacoesExtras();
            default:
                return null;
        }
    };

    return (
        <div style={{ backgroundColor: 'white' }}>
            {/* Navbar */}
            <nav className={`navbar navbar-expand-lg py-2 ${navbarBg} fixed-top w-100 z-3`}>
                <div className="container">
                    <a className="px-2 py-1 text-white bg-custom" href="https://cliente.taxupdate.com.br/" target="_blank">
                        ÁREA DE CLIENTE
                    </a>

                    <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
                        <ul className="navbar-nav ms-auto">
                            {['HOME', 'PASSOS'].map((item, index) => (
                                <li key={index} className="nav-item">
                                    <a
                                        className="nav-link active fw-bold"
                                        style={{ color: navbarColor }}
                                        onClick={() => handleScrollToSection(index === 0 ? 'topo' : 'steps')}
                                    >
                                        {item}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </nav>

            {/* Carousel */}
            <div
                className={`text-start py-5 bg-cover bg-center position-relative transition-opacity ${fade ? 'opacity-100' : 'opacity-0'}`}
                style={{ backgroundImage: `url(${IMAGES[currentImage]})`, minHeight: '500px' }}
                id="topo"
            >
                <button
                    className="carousel-control-prev position-absolute custom-carousel start-0 translate-middle-y z-3"
                    onClick={() => handleImageChange((currentImage - 1 + IMAGES.length) % IMAGES.length)}
                >
                    ❮
                </button>

                <button
                    className="carousel-control-next position-absolute custom-carousel end-0 translate-middle-y z-3 fs-2 fw-bold"
                    onClick={() => handleImageChange((currentImage + 1) % IMAGES.length)}
                >
                    ❯
                </button>

                <div className="container pt-5 mt-4">
                    {currentImage === 0 && (
                        <>
                            <h1 className="display-4 text-white fs-custom-title" id="hero">
                                SEM EXCESSOS, SEM FALTA DE<br />INFORMAÇÃO.
                            </h1>

                            <p className="lead text-white fs-custom-text">
                                AJUSTE AQUI O SEU ESCOPO PARA UM MONITORAMENTO PRECISO.
                            </p>
                        </>
                    )}

                    {currentImage === 1 && (
                        <>
                            <h1 className="display-4 text-white fs-custom-title" id="hero">
                                CUSTOMIZE SEU MONITORAMENTO<br />EM MINUTOS.
                            </h1>

                            <p className="lead text-white fs-custom-text">
                                ESCOLHA REGIÃO, TEMAS E PALAVRAS-CHAVE E RECENA APENAS O QUE INTERESSA.
                            </p>
                        </>
                    )}

                    {currentImage === 2 && (
                        <>
                            <h1 className="display-4 text-white fs-custom-title" id="hero">
                                PERSONALIZE SEU MONITORAMENTO<br />TRIBUTÁRIO.
                            </h1>

                            <p className="lead text-white fs-custom-text">
                                E RECEBA APENAS NORMAS QUE REALMENTE IMPORTAM PARA O SEU NEGÓCIO.
                            </p>
                        </>
                    )}

                    <div className="carousel-indicators position-absolute start-50 translate-middle-x d-flex gap-2 custom-bottom">
                        {IMAGES.map((_, index) => (
                            <span
                                key={index}
                                onClick={() => handleImageChange(index)}
                                className={`rounded-circle ${currentImage === index ? 'bg-white' : 'bg-secondary'}`}
                                style={{ width: '10px', height: '10px', cursor: 'pointer' }}
                            >
                            </span>
                        ))}
                    </div>
                </div>
            </div>

            {/* Steps */}
            <AnimatePresence>
                <motion.div
                    className="container pt-5 mt-5"
                    id="steps"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.3 }}
                >
                    <div className="row text-left">
                        {['Dados da Empresa', 'Escopo Geográfico', 'Regras de Negócio', 'Informações Extras'].map((passo, index) => {
                            const isActive = grupoAtual === (index + 1);
                            return (
                                <div key={index} className="col-md-3">
                                    <h4
                                        style={{
                                            color: isActive ? '#007bff' : '#6C737F',
                                            fontSize: '1.2rem',
                                            fontWeight: isActive ? 'bold' : 'normal',
                                            textDecoration: isActive ? 'underline' : 'none',
                                            transition: 'all 0.3s ease',
                                            cursor: 'pointer'
                                        }}
                                        data-group={`grupo${index + 1}`}
                                        onClick={handleStepClick}
                                    >
                                        {passo}
                                    </h4>
                                    <p style={{ color: '#aeaeae' }}>
                                        Passo {index + 1} de {totalGrupos}
                                    </p>
                                </div>
                            );
                        })}
                    </div>
                </motion.div>
            </AnimatePresence>

            {/* Formulário */}
            <AnimatePresence>
                <motion.div
                    className="container pt-5 mt-5 formulario"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.3 }}
                >
                    <motion.form
                        onSubmit={handleSubmit}
                        className="max-w-md mx-auto"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3 }}
                    >
                        <div className="d-flex justify-content-center align-items-center">
                            {renderGrupoAtual()}
                        </div>
                    </motion.form>

                    <div className="d-flex w-100 justify-content-between align-items-center mb-4 mt-4">
                        {grupoAtual === 1 && (
                            <p
                                className="rounded-pill px-4"
                            >

                            </p>
                        )}
                        {grupoAtual > 1 && (
                            <button
                                type="button"
                                className="btn btn-secondary rounded-pill px-4"
                                onClick={() => {
                                    setGrupoAtual(grupoAtual - 1);
                                    setPassoAtual(1);
                                }}
                            >
                                ❮ Voltar
                            </button>
                        )}
                        {grupoAtual < totalGrupos ? (
                            <button
                                type="button"
                                className="btn btn-success rounded-pill px-4"
                                onClick={proximoGrupo}
                            >
                                Avançar ❯
                            </button>
                        ) : (
                            <button
                                type="submit"
                                className="btn btn-primary rounded-pill px-4"
                                onClick={handleSubmit}
                            >
                                Concluir ✔
                            </button>
                        )}
                    </div>
                </motion.div>
            </AnimatePresence>

            {/* Modal */}
            <AnimatePresence>
                {isModalOpen && (
                    <motion.div
                        className="modal-overlay"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3 }}
                    >
                        <motion.div
                            className="modal-content"
                            initial={{ scale: 0.8, opacity: 0 }}
                            animate={{ scale: 1, opacity: 1 }}
                            exit={{ scale: 0.8, opacity: 0 }}
                            transition={{ duration: 0.3 }}
                        >
                            <h2>Confirme os dados</h2>

                            <div className="modal-body">
                                <div className="modal-section">
                                    <h3>Dados da Empresa</h3>

                                    <label>CNPJ da empresa</label>

                                    <input
                                        className='input'
                                        type="number"
                                        placeholder="CNPJ somente números..."
                                        name='cnpj'
                                        value={formData.dadosDaEmpresa.cnpj}
                                        data-group="dadosDaEmpresa"
                                        onChange={handleChange}
                                        required
                                        style={{ marginBottom: '1rem' }}
                                    />

                                    <label>Razão social da empresa</label>

                                    <input
                                        className='input'
                                        type="text"
                                        placeholder="Razão Social..."
                                        name='razaoSocial'
                                        value={formData.dadosDaEmpresa.razaoSocial}
                                        data-group="dadosDaEmpresa"
                                        onChange={handleChange}
                                        required
                                        style={{ marginBottom: '1rem' }}
                                    />

                                    <label>Endereço da empresa</label>

                                    <input
                                        className='input'
                                        type="text"
                                        placeholder="Endereço..."
                                        name='endereco'
                                        value={formData.dadosDaEmpresa.endereco}
                                        data-group="dadosDaEmpresa"
                                        onChange={handleChange}
                                        style={{ marginBottom: '1rem' }}
                                    />

                                    <label>Contato comercial</label>

                                    <input
                                        className='input'
                                        type="text"
                                        placeholder="Telefone somente números..."
                                        name='telefone'
                                        value={formData.dadosDaEmpresa.telefone}
                                        data-group="dadosDaEmpresa"
                                        onChange={handleChange}
                                        style={{ marginBottom: '1rem' }}
                                    />

                                    <label>Responsável comercial</label>

                                    <input
                                        className='input'
                                        type="text"
                                        placeholder="Responsável..."
                                        name='responsavel'
                                        value={formData.dadosDaEmpresa.responsavel}
                                        data-group="dadosDaEmpresa"
                                        onChange={handleChange}
                                        style={{ marginBottom: '1rem' }}
                                    />

                                    <label>Lista de e-mails comerciais. Caso houver mais de um, separar por virgula ou ponto e vírgula</label>

                                    <textarea
                                        type="text"
                                        name="emailComercial"
                                        data-group="dadosDaEmpresa"
                                        value={formData.dadosDaEmpresa.emailComercial}
                                        onChange={handleChange}
                                        className="text_area"
                                        placeholder="Digite os e-mails..."
                                        required
                                        style={{ marginBottom: '1rem' }}
                                    />
                                </div>

                                <div className="modal-section">
                                    <h3>Dados da Área de Atuação</h3>

                                    <label>Selecione os locais de acompanhamento FEDERAL</label>

                                    <Select
                                        value={selectedOptionsAreaDeAtuacaoF}
                                        isMulti
                                        name="selectedOptionsAreaDeAtuacaoF"
                                        data-group="dadosDaAreaDeAtuacao"
                                        options={optionsF}
                                        className="basic-multi-select mb-3"
                                        classNamePrefix="select"
                                        onChange={handleChangeAreaDeAtuacaoF}
                                        placeholder="Selecione..."
                                        noOptionsMessage={() => "Sem resultados"}
                                        required
                                        style={{ marginBottom: '1rem' }}
                                    />

                                    <label>Selecione os locais de acompanhamento ESTADUAL</label>

                                    <Select
                                        value={selectedOptionsAreaDeAtuacaoE}
                                        isMulti
                                        name="selectedOptionsAreaDeAtuacaoE"
                                        data-group="dadosDaAreaDeAtuacao"
                                        options={optionsE}
                                        className="basic-multi-select mb-3"
                                        classNamePrefix="select"
                                        onChange={handleChangeAreaDeAtuacaoE}
                                        placeholder="Selecione..."
                                        noOptionsMessage={() => "Sem resultados"}
                                        style={{ marginBottom: '1rem' }}
                                    />

                                    <label>Selecione os locais de acompanhamento MUNICIPAL</label>

                                    <Select
                                        value={selectedOptionsAreaDeAtuacaoM}
                                        isMulti
                                        name="selectedOptionsAreaDeAtuacaoM"
                                        data-group="dadosDaAreaDeAtuacao"
                                        options={optionsM}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        onChange={handleChangeAreaDeAtuacaoM}
                                        placeholder="Selecione..."
                                        noOptionsMessage={() => "Sem resultados"}
                                        style={{ marginBottom: '1rem' }}
                                    />
                                </div>

                                <div className="modal-section" style={{ marginTop: "40px" }}>
                                    <h3>Regras</h3>

                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={formData.regras.acompanhamentoNacional}
                                            onChange={(e) => handleChange({
                                                target: {
                                                    name: "acompanhamentoNacional",
                                                    value: e.target.checked,
                                                    dataset: { group: "regras" }
                                                }
                                            })}
                                        />

                                        <span className='ml-2'>Há algum estado em que deseja apenas o acompanhamento legislativo para fins de substituição tributária?</span>
                                    </label>

                                    {formData.regras.acompanhamentoNacional && (
                                        <div className="mt-2">
                                            <label>Selecione estados acompanhados apenas para substituição tributária</label>

                                            <Select
                                                value={selectedOptionsEstadosAcompanhados}
                                                isMulti
                                                name="estadosAcompanhados"
                                                options={optionsSubTributaria}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                onChange={handleChangeEstadosAcompanhados}
                                                placeholder="Selecione..."
                                                noOptionsMessage={() => "Sem resultados"}
                                            />
                                        </div>
                                    )}
                                </div>

                                <div className="modal-section" style={{ marginTop: "40px" }}>
                                    <h3>Informações Extras</h3>

                                    <label>Quais NCMs deseja acompanhamento</label>

                                    <textarea
                                        type="text"
                                        name="ncms"
                                        value={formData.informacoesExtras.ncms}
                                        data-group="informacoesExtras"
                                        onChange={handleChange}
                                        className="text_area"
                                        placeholder="NCMs..."
                                        required
                                        style={{ marginBottom: '1rem' }}
                                    />

                                    <label>Quais CESTs deseja acompanhamento</label>

                                    <textarea
                                        type="text"
                                        name="cests"
                                        value={formData.informacoesExtras.cests}
                                        data-group="informacoesExtras"
                                        onChange={handleChange}
                                        className="text_area"
                                        placeholder="CESTs..."
                                        required
                                        style={{ marginBottom: '1rem' }}
                                    />

                                    <label>Se houver marcas associadas quais são</label>

                                    <textarea
                                        type="text"
                                        name="marcasAssociadas"
                                        value={formData.informacoesExtras.marcasAssociadas}
                                        data-group="informacoesExtras"
                                        onChange={handleChange}
                                        className="text_area"
                                        placeholder="Marcas associadas..."
                                        style={{ marginBottom: '1rem' }}
                                    />

                                    <label>Nos conte um pouco mais sobre a empresa, escopo de atuação, número de filiais, etc</label>

                                    <textarea
                                        type="text"
                                        name="informacoesSobreAEmpresa"
                                        value={formData.informacoesExtras.informacoesSobreAEmpresa}
                                        data-group="informacoesExtras"
                                        onChange={handleChange}
                                        className="text_area"
                                        placeholder="Informações sobre a empresa..."
                                        required
                                        style={{ marginBottom: '1rem' }}
                                    />

                                    <label>Quais palavras chave acha relevante para o acompanhamento, ex: 'Reforma Tributária', 'ICMS', etc</label>

                                    <textarea
                                        type="text"
                                        name="palavrasChaveParaAcompanhamento"
                                        value={formData.informacoesExtras.palavrasChaveParaAcompanhamento}
                                        data-group="informacoesExtras"
                                        onChange={handleChange}
                                        className="text_area"
                                        placeholder="Palavras-chave..."
                                        required
                                    />
                                </div>
                            </div>

                            <div className="modal-actions">
                                <button className="btn btn-secondary" onClick={(e) => setIsModalOpen(false)}>Cancelar</button>

                                <button className="btn btn-primary" onClick={handleConfirm}>Confirmar</button>
                            </div>
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>

            {/* Modal de Mensagem */}
            <AnimatePresence>
                {isMessageOpen && (
                    <motion.div
                        className="modal-overlay"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3 }}
                    >
                        <motion.div
                            className="modal-content-message"
                            initial={{ y: 50, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            exit={{ y: -50, opacity: 0 }}
                            transition={{ duration: 0.3 }}
                        >
                            <h2>{message}</h2>
                            <button onClick={() => setIsMessageOpen(false)} className="btn btn-primary">
                                OK
                            </button>
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>

            {/* Footer */}
            <div className="pt-5 mt-5">
                <footer style={{ backgroundColor: "#ECF5F3" }} className="py-4">
                    <div className="container">
                        <div className="row justify-content-center text-center">
                            <div className="col-12">
                                <div className="mb-4 d-flex justify-content-center" style={{ gap: "5px" }}>
                                    <a href="https://www.linkedin.com/company/taxupdate/" target="_blank">
                                        <button type="button" className="btn btn-secondary rounded-circle d-flex align-items-center justify-content-center" style={{ width: "40px", height: "40px" }}>
                                            <FaLinkedin />
                                        </button>
                                    </a>

                                    <a href="https://taxupdate.com.br/" target="_blank">
                                        <button type="button" className="btn btn-secondary rounded-circle d-flex align-items-center justify-content-center" style={{ width: "40px", height: "40px", fontSize: "30px" }}>
                                            <TbWorld />
                                        </button>
                                    </a>
                                </div>

                                <p style={{ color: "#333", fontWeight: "bold" }}>
                                    © {new Date().getFullYear()} TaxUpdate
                                </p>

                                <p className="mb-0" style={{ color: "#333" }}>Todos os direitos reservados</p>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </div >
    );
};

export default Trial;