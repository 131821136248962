import React from "react";

import { useTranslation } from "react-i18next";

const NormasPublicadas = ({ title, staticDatas }) => {
    const { t } = useTranslation();

    const storedProdutoAtivo = localStorage.getItem("produto_ativo");

    return (
        <div style={{ borderRadius: "1rem" }}>
            <div className="p-4" style={{ borderRadius: "1rem" }}>
                <div
                    className="custom-normal"
                    style={{ fontSize: "16px", fontWeight: "600", color: "#676A6C" }}
                >
                    {title}
                </div>

                <div className="d-flex  mt-4 row">
                    <div className="col-4">
                        <div
                            className="card p-4 border-0 shadow-sm text-center"
                            style={{ borderRadius: "1rem", background: "#ECF5F3" }}
                        >
                            <div className="d-flex flex-column align-items-start">
                                <div className="text-black-50">
                                    {" "}
                                    {t("dadosEstatisticos.federal")}
                                </div>

                                <h2
                                    className="mt-1 custom-normal"
                                    style={{ fontSize: "20px", fontWeight: "600" }}
                                >
                                    {staticDatas.normas_federal}
                                </h2>
                            </div>
                        </div>
                    </div>

                    <div className="col-4">
                        <div
                            className="card p-4 border-0 shadow-sm text-center"
                            style={{ borderRadius: "1rem", background: "#ECF5F3" }}
                        >
                            <div className="d-flex flex-column align-items-start">
                                <div className="text-black-50">
                                    {" "}
                                    {t("dadosEstatisticos.state")}
                                </div>

                                <h2
                                    className="mt-1 custom-normal"
                                    style={{ fontSize: "20px", fontWeight: "600" }}
                                >
                                    {staticDatas.normas_estadual}
                                </h2>
                            </div>
                        </div>
                    </div>

                    {storedProdutoAtivo !== "latam" && (
                        <div className="col-4">
                            <div
                                className="card p-4  border-0 shadow-sm text-center"
                                style={{ borderRadius: "1rem", background: "#ECF5F3" }}
                            >
                                <div className="d-flex flex-column align-items-start">
                                    <div className="text-black-50">
                                        {" "}
                                        {t("dadosEstatisticos.municipal")}
                                    </div>

                                    <h2
                                        className="mt-1 custom-normal"
                                        style={{ fontSize: "20px", fontWeight: "600" }}
                                    >
                                        {staticDatas.normas_municipal}
                                    </h2>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default NormasPublicadas;
