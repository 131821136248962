import React from "react";

import { useTranslation } from "react-i18next";

import { useBibliotecaContext } from "../../context/BibliotecaContext";

export function OpenFilter() {
    const { t } = useTranslation();

    const { filtroAberto, setFiltroAberto } = useBibliotecaContext();

    const handleChange = (event) => {
        setFiltroAberto(event.target.value);
    };

    return (
        <div className="mt-4 mb-3">
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <input
                    type="text"
                    name="filtroAberto"
                    className="form-control rounded-lg"
                    placeholder={t("biblioteca.openFilter")}
                    value={filtroAberto}
                    onChange={handleChange}
                    style={{
                        height: "40px",
                        flex: 1,
                    }}
                />
            </div>
        </div>
    );
}
