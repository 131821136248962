import React, { createContext, useState, useContext } from 'react';

const BibliotecaContext = createContext();

export const useBibliotecaContext = () => {
    return useContext(BibliotecaContext);
};

export const BibliotecaProvider = ({ children }) => {

    const [normasData, setNormasData] = useState({ normas: [] });
    const [estadosData, setEstadosData] = useState(null);
    const [municipiosData, setMunicipiosData] = useState(null);
    const [impostosData, setImpostosData] = useState(null);
    const [segmentosData, setSegmentosData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [expandedId, setExpandedId] = useState(null);
    const [dateStart, setDateStart] = useState("");
    const [dateEnd, setDateEnd] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [estadosSelecionados, setEstadosSelecionados] = useState([]);
    const [municipiosSelecionados, setMunicipiosSelecionados] = useState([]);
    const [impostosSelecionados, setImpostosSelecionados] = useState([]);
    const [numeroDaLei, setNumeroDaLei] = useState('');
    const [filtroAberto, setFiltroAberto] = useState('');
    const [ncm, setNcm] = useState('');
    const [segmentosSelecionados, setSegmentosSelecionados] = useState([]);
    const [normasNaoInformadas, setNormasNaoInformadas] = useState(1);
    const [isSmartSearchOpen, setIsSmartSearchOpen] = useState(false);
    const [page, setPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [hasMore, setHasMore] = useState(true);

    return (
        <BibliotecaContext.Provider value={
            {
                normasData, setNormasData,
                estadosData, setEstadosData,
                municipiosData, setMunicipiosData,
                impostosData, setImpostosData,
                segmentosData, setSegmentosData,
                loading, setLoading,
                error, setError,
                expandedId, setExpandedId,
                dateStart, setDateStart,
                dateEnd, setDateEnd,
                searchQuery, setSearchQuery,
                estadosSelecionados, setEstadosSelecionados,
                municipiosSelecionados, setMunicipiosSelecionados,
                impostosSelecionados, setImpostosSelecionados,
                numeroDaLei, setNumeroDaLei,
                filtroAberto, setFiltroAberto,
                ncm, setNcm,
                segmentosSelecionados, setSegmentosSelecionados,
                normasNaoInformadas, setNormasNaoInformadas,
                isSmartSearchOpen, setIsSmartSearchOpen,
                page, setPage,
                itemsPerPage, setItemsPerPage,
                hasMore, setHasMore
            }
        }>
            {children}
        </BibliotecaContext.Provider>
    );
};
