import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";

import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";

import { apiRequest } from "../api/apiRequest.js";

import Loading from "../components/Loading";
import Navbar from "../components/Navbar";
import SideMenu from "../components/SideMenu";

import ebookData from "../utils/ebooks.json";

import { useLeiturasTecnicasContext } from "../context/LeiturasTecnicasContext";

function Leituras() {
    const { t } = useTranslation();

    const {
        activeMenu, setActiveMenu,
        postsData, setPostsData,
        error, setError,
        loading, setLoading,
        page, setPage,
        itemsPerPage, setItemsPerPage,
        hasMore, setHasMore
    } = useLeiturasTecnicasContext();

    const startItem = page * itemsPerPage + 1;
    const endItem = startItem + postsData.posts?.length - 1;

    const formatDate = (dateString) => {
        if (dateString !== null) {
            const [datePart] = dateString.split(" ");
            const [year, month, day] = datePart.split("-");

            return `${day}/${month}/${year}`;
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setError(null);
            setHasMore(true);

            const tipoPost = activeMenu !== "todos" ? "&tipo_post=" + activeMenu : "";
            const pagination = "&pagina=" + page;

            try {
                const postsResponse = await apiRequest(
                    "GET",
                    `/dashboard/posts?limite_quantidade=${itemsPerPage}${tipoPost}${pagination}`
                );

                if (postsResponse?.data?.posts?.length) {
                    setPostsData(postsResponse.data);

                    if (postsResponse?.data?.posts?.length < itemsPerPage) {
                        setHasMore(false);
                    }
                } else {
                    setHasMore(false);
                }
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        if (activeMenu !== "ebook") {
            fetchData();
        }
    }, [activeMenu, page, itemsPerPage]);

    if (error) return <p className="mx-3">{t("dashboard.errorMessage")}</p>;

    return (
        <div id="wrapper" style={{ backgroundColor: "#043A5E" }}>
            <SideMenu />

            <div
                id="page-wrapper"
                className="pb-5"
                style={{ backgroundColor: "#FCFCFD" }}
            >
                <Navbar />

                <div
                    className="pl-2 mt-4 mb-3 custom-normal"
                    style={{ fontSize: "38px", fontWeight: "600" }}
                >
                    {t("leituras.title")}
                </div>

                <div
                    className="bg-white shadow-sm d-flex flex-column justify-content-between"
                    style={{ borderRadius: "1rem", minHeight: "100vh" }}
                >
                    <div>
                        <nav className="navbar navbar-expand-lg navbar-light">
                            <ul
                                className="nav nav-pills w-100"
                                style={{ borderBottom: "1px solid #F3F4F7" }}
                            >
                                {[
                                    { tipo: "todos", title: t("leituras.type.all") },
                                    { tipo: "artigo", title: t("leituras.type.articles") },
                                    { tipo: "roteiro", title: t("leituras.type.scripts") },
                                    { tipo: "ebook", title: t("leituras.type.ebook") },
                                ].map((menu) => (
                                    <li key={menu}>
                                        <div
                                            className={`mr-5 ${activeMenu === menu.tipo ? "active-menu" : ""
                                                }`}
                                            style={{
                                                cursor: "pointer",
                                                paddingBottom: "10px",
                                                paddingTop: "5px",
                                            }}
                                            onClick={() => setActiveMenu(menu.tipo)}
                                        >
                                            {menu.title}
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </nav>

                        <div className="container" style={{ marginTop: "20px" }}>
                            {loading && <Loading />}

                            <div className="row">
                                {/* Banners das Leituras Artigo e Roteiro*/}
                                {!loading &&
                                    activeMenu !== "ebook" &&
                                    postsData?.posts &&
                                    postsData.posts.map((item) => (
                                        <div className="col-6 flex-wrap pb-4 align-items-stretch">
                                            <a href={item.url_origem} target="_blank">
                                                <div
                                                    className=""
                                                    style={{
                                                        borderRadius: "1rem 1rem 0 0",
                                                        backgroundColor: "#205070",
                                                        backgroundImage: `url("${item.thumb}")`,
                                                        backgroundPosition: "center",
                                                        backgroundSize: "cover",
                                                        backgroundRepeat: "no-repeat",
                                                        height: "287px",
                                                        display: "block",
                                                        width: "100%",
                                                    }}
                                                ></div>

                                                {/* Parte branca dos posts */}
                                                <div
                                                    className="border-0 shadow-sm w-100 px-3 py-3 d-flex flex-column align-items-start"
                                                    style={{
                                                        borderRadius: "0 0 1rem 1rem",
                                                        backgroundColor: "#FFFFFF",
                                                    }}
                                                >
                                                    <div
                                                        className="px-3 py-1 text-white"
                                                        style={{
                                                            backgroundColor:
                                                                item.tipo === "roteiro" ? "#043A5E" : "#429A85",
                                                            borderRadius: "1rem",
                                                            fontWeight: "600",
                                                        }}
                                                    >
                                                        {item.tipo.charAt(0).toUpperCase() +
                                                            item.tipo.slice(1)}
                                                    </div>

                                                    <h2
                                                        className=""
                                                        style={{
                                                            fontSize: "20px",
                                                            fontWeight: "600",
                                                            color: "#111927",
                                                        }}
                                                    >
                                                        {item.titulo}
                                                    </h2>

                                                    <p
                                                        style={{
                                                            display: "-webkit-box",
                                                            "-webkit-box-orient": "vertical",
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            "-webkit-line-clamp": "2",
                                                            lineHeight: "1.5em",
                                                            maxHeight: "3em",
                                                            color: "#6C737F",
                                                            maxWidth: "100%",
                                                        }}
                                                    >
                                                        {item.conteudo}
                                                    </p>

                                                    <div
                                                        className=""
                                                        style={{
                                                            fontSize: "12px",
                                                            fontWeight: "400",
                                                            marginBottom: "12px",
                                                            color: "#111927",
                                                        }}
                                                    >
                                                        {formatDate(item.criado)}
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    ))}

                                {/* Banners Ebooks*/}
                                {!loading && activeMenu == "ebook" && (
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "flex-start",
                                            gap: "5px",
                                            marginLeft: "15px",
                                            marginBottom: "15px",
                                        }}
                                    >
                                        {ebookData.ebooks.map((ebook) => {
                                            return (
                                                <ul>
                                                    <li>
                                                        <a
                                                            href={ebook.link}
                                                            target="_blank"
                                                            style={{
                                                                fontSize: "20px",
                                                            }}
                                                        >
                                                            {ebook.title}
                                                        </a>
                                                    </li>
                                                </ul>
                                            );
                                        })}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    {/* Paginação */}
                    {activeMenu !== "ebook" && (
                        <div className="d-flex justify-content-end align-items-center py-2">
                            <div className="mr-4">{t("leituras.documentsPerPage")}</div>

                            <select
                                className="mr-4"
                                style={{ border: "none" }}
                                value={itemsPerPage}
                                onChange={(e) => {
                                    setItemsPerPage(parseInt(e.target.value));
                                    setPage(0);
                                }}
                            >
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="20">20</option>
                            </select>

                            <div className="mr-4">
                                {t("leituras.showing")} {startItem}-{endItem}
                            </div>

                            <div
                                className="mr-4"
                                style={{
                                    fontSize: "20px",
                                    cursor: page === 0 ? "default" : "pointer",
                                    opacity: page === 0 ? 0.5 : 1,
                                }}
                                onClick={() => {
                                    if (page > 0) {
                                        setPage(page - 1);
                                    }
                                }}
                            >
                                <RiArrowLeftSLine />
                            </div>

                            <div
                                className="mr-4"
                                style={{
                                    fontSize: "20px",
                                    cursor: hasMore ? "pointer" : "default",
                                    opacity: hasMore ? 1 : 0.5,
                                }}
                                onClick={() => {
                                    if (hasMore) {
                                        setPage(page + 1);
                                    }
                                }}
                            >
                                <RiArrowRightSLine />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Leituras;
