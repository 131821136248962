import React, { createContext, useState, useContext } from 'react';

const ContaContext = createContext();

export const useContaContext = () => {
    return useContext(ContaContext);
};

export const ContaProvider = ({ children }) => {

    const [activeMenu, setActiveMenu] = useState("Geral");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [userDataGeral, setUserDataGeral] = useState({
        nome: "",
        email: "",
        senha: "",
        whatsapp: "",
    });
    const [userDataNotificacao, setUserDataNotificacao] = useState({
        notificacaoEmail: 0,
        notificacaoWhatsapp: 0,
    });

    return (
        <ContaContext.Provider value={
            {
                activeMenu, setActiveMenu,
                loading, setLoading,
                error, setError,
                userDataGeral, setUserDataGeral,
                userDataNotificacao, setUserDataNotificacao
            }
        }>
            {children}
        </ContaContext.Provider>
    );
};
