import React from "react";

import { useTranslation } from "react-i18next";

import Select from "react-select";

import { useBibliotecaContext } from "../../context/BibliotecaContext";

export function MunicipalityFilter() {
    const { t } = useTranslation();

    const { municipiosData, municipiosSelecionados, setMunicipiosSelecionados } = useBibliotecaContext();

    const options = municipiosData
        ? municipiosData.map((municipio) => ({
            label: `${municipio.nome}`,
            value: municipio.areaatuacao_id,
        }))
        : [];

    const selectedOptions = options.filter((option) =>
        municipiosSelecionados.includes(option.value)
    );

    const handleChange = (selectedOptions) => {
        setMunicipiosSelecionados(
            selectedOptions ? selectedOptions.map((option) => option.value) : []
        );
    };

    return (
        <div className="mt-4">
            <div className="mb-2">{t("biblioteca.city")}</div>

            <Select
                value={selectedOptions}
                isMulti
                name="cidades"
                options={options}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={handleChange}
                placeholder={t("biblioteca.selectCity")}
                noOptionsMessage={() => {
                    t("biblioteca.emptyCity");
                }}
            />
        </div>
    );
}
