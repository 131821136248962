import React, { useEffect } from "react";

import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";

import { apiRequest } from "../api/apiRequest";

import Navbar from "../components/Navbar";
import SideMenu from "../components/SideMenu";

import { useCalendarioContext } from "../context/CalendarioContext";

const modalStyles = {
    overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0,0,0,0.5)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 1000,
    },
    content: {
        backgroundColor: "#fff",
        padding: "20px",
        borderRadius: "8px",
        width: "400px",
        maxWidth: "90%",
        position: "relative",
    },
    closeBtn: {
        position: "absolute",
        top: "10px",
        right: "10px",
        cursor: "pointer",
        fontSize: "18px",
        border: "none",
        background: "none",
    },
};

const calendar_styles = {
    calendarContainer: {
        display: "flex",
        flexDirection: "column",
        margin: "0 auto",
    },
    weekDaysContainer: {
        display: "grid",
        gridTemplateColumns: "repeat(7, 1fr)",
        fontWeight: "bold",
        textAlign: "center",
    },
    weekDay: { padding: "10px" },
    daysContainer: {
        display: "grid",
        gridTemplateColumns: "repeat(7, 1fr)",
        gridTemplateRows: "repeat(6, 1fr)",
    },
    dayBlock: {
        position: "relative",
        width: "100%",
        padding: "55px",
        backgroundColor: "#fff",
        textAlign: "center",
    },
    dayNumber: {
        position: "absolute",
        top: "10px",
        right: "10px",
        fontSize: "14px",
    },
    eventsContainer: {
        position: "absolute",
        bottom: "10px",
        left: "0px",
        right: "0px",
        textAlign: "left",
        fontSize: "12px",
    },
    event: {
        fontSize: "12px",
        width: "100%",
        backgroundColor: "#449A86",
        color: "#fff",
        paddingLeft: "10px",
        borderRadius: "5px",
        fontWeight: "600",
        marginBottom: "2px",
    },
};

function Calendario() {
    const { t } = useTranslation();

    const {
        year, setYear,
        month, setMonth,
        eventos, setEventos,
        loading, setLoading,
        error, setError,
        isModalOpen, setIsModalOpen,
        areaatuacaoId, setAreaatuacaoId,
        descricao, setDescricao,
        dataCompromisso, setDataCompromisso,
        areasDeAtuacao, setAreasDeAtuacao,
        loadingAreas, setLoadingAreas,
        errorAreas, setErrorAreas,
        editEvent, setEditEvent,
        isEditModalOpen, setIsEditModalOpen,
        editModalType, setEditModalType
    } = useCalendarioContext();

    useEffect(() => {
        const fetchAreasDeAtuacao = async () => {
            setLoadingAreas(true);
            setErrorAreas(null);

            try {
                const estadosResponse = await apiRequest("GET", `/filtros/estados`);
                const estados = estadosResponse.data;

                const municipiosResponse = await apiRequest(
                    "GET",
                    `/filtros/municipios`
                );

                const municipios = municipiosResponse.data;

                const federalResponse = await apiRequest("GET", `/filtros/federal`);
                const federal = federalResponse.data;

                // Combinar as listas
                const combinedAreas = [...estados, ...municipios, ...federal];

                // Mapear para incluir apenas id e nome
                const areasList = combinedAreas.map((area) => ({
                    id: area.areaatuacao_id,
                    nome: area.nome,
                }));

                setAreasDeAtuacao(areasList);
            } catch (err) {
                setErrorAreas(t("calendario.failAreasMessage"));
            } finally {
                setLoadingAreas(false);
            }
        };

        fetchAreasDeAtuacao();
    }, []);

    const allMonths = [
        t("calendario.months.january"),
        t("calendario.months.february"),
        t("calendario.months.march"),
        t("calendario.months.april"),
        t("calendario.months.may"),
        t("calendario.months.june"),
        t("calendario.months.july"),
        t("calendario.months.august"),
        t("calendario.months.september"),
        t("calendario.months.october"),
        t("calendario.months.november"),
        t("calendario.months.december"),
    ];

    const daysOfWeek = [
        t("calendario.dayOfWeek.sunday"),
        t("calendario.dayOfWeek.monday"),
        t("calendario.dayOfWeek.tuesday"),
        t("calendario.dayOfWeek.wednesday"),
        t("calendario.dayOfWeek.thursday"),
        t("calendario.dayOfWeek.friday"),
        t("calendario.dayOfWeek.saturday"),
    ];

    const today = new Date();
    const todayDate = today.getDate();
    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();

    const getDaysInMonth = (year, month) => {
        return new Date(year, month + 1, 0).getDate();
    };

    const generateCalendar = (year, month) => {
        const daysInCurrentMonth = getDaysInMonth(year, month);
        const firstDayOfMonth = new Date(year, month, 1).getDay();
        const nextMonth = month === 11 ? 1 : month + 1;

        let daysArray = [];

        if (firstDayOfMonth > 0) {
            const prevMonth = month === 0 ? 11 : month - 1;
            const prevYear = month === 0 ? year - 1 : year;
            const prevMonthDays = getDaysInMonth(prevYear, prevMonth);

            for (let i = firstDayOfMonth - 1; i >= 0; i--) {
                daysArray.push({
                    day: prevMonthDays - i,
                    month: prevMonth,
                    isCurrentMonth: false,
                });
            }
        }

        for (let i = 1; i <= daysInCurrentMonth; i++) {
            daysArray.push({ day: i, month, isCurrentMonth: true });
        }

        while (daysArray.length < 42) {
            const nextMonthDay = daysArray.length - daysInCurrentMonth - firstDayOfMonth + 1;

            daysArray.push({
                day: nextMonthDay,
                month: nextMonth,
                isCurrentMonth: false,
            });
        }

        return daysArray;
    };

    const calendarDays = generateCalendar(year, month);

    const isLastColumn = (index) => (index + 1) % 7 === 0;

    const getEventsForDay = (day) => {
        return eventos.filter((event) => {
            const eventDate = new Date(event.start);

            return (
                eventDate.getDate() === day &&
                eventDate.getMonth() === month &&
                eventDate.getFullYear() === year
            );
        });
    };

    const fetchEventos = async () => {
        setLoading(true);
        setError(null);

        try {
            const formattedMonth = String(month + 1).padStart(2, "0");
            const response = await apiRequest(
                "GET",
                `/calendario?mes=${formattedMonth}&ano=${year}`
            );

            setEventos(response.data);
        } catch (err) {
            setError(t("calendario.failEventMessage"));
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchEventos();
    }, [month, year, isModalOpen]);

    const handlePreviousMonth = () => {
        setMonth((prevMonth) => {
            if (prevMonth === 0) {
                setYear(year - 1);

                return 11; // Vai para dezembro
            } else {
                return prevMonth - 1;
            }
        });
    };

    const handleNextMonth = () => {
        setMonth((prevMonth) => {
            if (prevMonth === 11) {
                setYear(year + 1);

                return 0; // Vai para janeiro
            } else {
                return prevMonth + 1;
            }
        });
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const openModalDaySelect = (dateSelect, monthSelect) => {
        var monthCompromisso = String(monthSelect + 1).padStart(2, "0");
        const dateCompromiso = String(dateSelect).padStart(2, "0");

        setDataCompromisso(`${year}-${monthCompromisso}-${dateCompromiso}`);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setAreaatuacaoId("");
        setDescricao("");
        setDataCompromisso("");
    };

    const closeEditModal = () => {
        setIsEditModalOpen(false);
        setEditEvent(null);
        setEditModalType(null);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!areaatuacaoId || !descricao || !dataCompromisso) {
            alert(t("calendario.addNewEvent.alertFields"));

            return;
        }

        const data = JSON.stringify({
            areaatuacao_id: parseInt(areaatuacaoId),
            descricao: descricao,
            data_compromisso: dataCompromisso,
        });

        try {
            const response = await apiRequest("POST", `/calendario`, data);

            setEventos([...eventos, response.data]);
            closeModal();
        } catch (error) {
            alert(t("calendario.addNewEvent.failAdd"));
        }
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();

        if (!editEvent.areaatuacao_id || !editEvent.title || !editEvent.start) {
            alert(t("calendario.editEvent.alertFillFields"));

            return;
        }

        const data = JSON.stringify({
            empresa_id: editEvent.empresa_id,
            areaatuacao_id: parseInt(editEvent.areaatuacao_id),
            descricao: editEvent.title,
            data_compromisso: editEvent.start,
            registrado_por_email: editEvent.registrado_por_email,
        });

        try {
            const response = await apiRequest(
                "PUT",
                `/calendario/${editEvent.id}`,
                data
            );

            setEventos([...eventos, response.data]);

            closeEditModal();
            fetchEventos();
        } catch (error) {
            alert(t("calendario.editEvent.failEdit"));
        }
    };

    const handleDelete = async (e) => {
        e.preventDefault();

        try {
            const response = await apiRequest(
                "DELETE",
                `/calendario/${editEvent.id}`
            );

            setEventos([...eventos, response.data]);

            closeEditModal();
            fetchEventos();
        } catch (error) {
            alert(t("calendario.editEvent.failDelete"));
        }
    };

    const handleEditEvent = (event) => {
        setEditEvent(event);
        setEditModalType(event.tipo);
        setIsEditModalOpen(true);
    };

    return (
        <div id="wrapper" style={{ backgroundColor: "#043A5E" }}>
            <SideMenu />

            <div
                id="page-wrapper"
                className="pb-5"
                style={{ backgroundColor: "#FCFCFD" }}
            >
                <Navbar />

                <div
                    className="pl-2 mt-4 mb-3 custom-normal"
                    style={{ fontSize: "38px", fontWeight: "600" }}
                >
                    {t("calendario.title")}
                </div>

                <div className="d-flex justify-content-between align-items-center p-4">
                    <div className="d-flex justify-content-between">
                        <div className="d-flex">
                            <div
                                className="mr-2"
                                style={{
                                    fontSize: "20px",
                                    fontWeight: "600",
                                    color: "#111927",
                                }}
                            >
                                {allMonths[month]}
                            </div>

                            <div style={{ fontSize: "20px", fontWeight: "400" }}>{year}</div>
                        </div>
                    </div>

                    <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex justify-content-between align-items-center">
                            <div
                                className="mr-4"
                                style={{ fontSize: "25px", cursor: "pointer" }}
                                onClick={handlePreviousMonth}
                            >
                                <RiArrowLeftSLine />
                            </div>

                            <div
                                className="mr-4"
                                style={{ fontSize: "25px", cursor: "pointer" }}
                                onClick={handleNextMonth}
                            >
                                <RiArrowRightSLine />
                            </div>
                        </div>

                        <div
                            className="text-white"
                            style={{
                                backgroundColor: "#449A86",
                                borderRadius: "16px",
                                fontSize: "14px",
                                padding: "0.7rem 1.2rem",
                                cursor: "pointer",
                            }}
                            onClick={openModal}
                        >
                            + {t("calendario.newEvent")}
                        </div>
                    </div>
                </div>

                <div
                    className="bg-white border-0 shadow-sm"
                    style={{ borderRadius: "1rem" }}
                >
                    <div style={calendar_styles.calendarContainer}>
                        <div style={calendar_styles.weekDaysContainer}>
                            {daysOfWeek.map((day, index) => (
                                <div
                                    style={{
                                        ...calendar_styles.weekDay,
                                        borderRight: isLastColumn(index)
                                            ? "none"
                                            : "1px solid #e0e0e0",
                                    }}
                                    key={day}
                                >
                                    {day}
                                </div>
                            ))}
                        </div>

                        <div style={calendar_styles.daysContainer}>
                            {calendarDays.map((dayObj, index) => {
                                const { day, month } = dayObj;
                                const events = getEventsForDay(dayObj.day - 1);
                                const isToday =
                                    dayObj.isCurrentMonth &&
                                    dayObj.day === todayDate &&
                                    year === currentYear &&
                                    month === currentMonth;

                                return (
                                    <div
                                        style={{
                                            ...calendar_styles.dayBlock,
                                            color: dayObj.isCurrentMonth ? "#101927" : "#C6C6C6",
                                            backgroundColor: isToday
                                                ? "#FFDEBF"
                                                : dayObj.isCurrentMonth
                                                    ? "#fff"
                                                    : "#f0f0f0",
                                            borderTop: "1px solid #e0e0e0",
                                            borderRight: isLastColumn(index)
                                                ? "none"
                                                : "1px solid #e0e0e0",
                                            cursor: dayObj.isCurrentMonth ? "pointer" : "default",
                                        }}
                                        key={index}
                                        onClick={() => openModalDaySelect(day, month)}
                                    >
                                        <span style={calendar_styles.dayNumber}>{dayObj.day}</span>

                                        {events.length > 0 && dayObj.isCurrentMonth && (
                                            <div style={calendar_styles.eventsContainer}>
                                                {events.map((event) => (
                                                    <div
                                                        key={event.id}
                                                        style={calendar_styles.event}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleEditEvent(event);
                                                        }}
                                                    >
                                                        {event.title}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>

                {loading && (
                    <p className="text-center mt-3">{t("calendario.loadingEvent")}</p>
                )}

                {error && <p className="text-center mt-3 text-danger">{error}</p>}

                {/* Modal para Adicionar Novo Evento */}
                {isModalOpen && (
                    <div style={modalStyles.overlay}>
                        <div style={modalStyles.content}>
                            <button style={modalStyles.closeBtn} onClick={closeModal}>
                                &times;
                            </button>

                            <h2>{t("calendario.addNewEvent.title")}</h2>

                            {loadingAreas ? (
                                <p>Carregando áreas de atuação...</p>

                            ) : errorAreas ? (
                                <p className="text-danger">{errorAreas}</p>
                            ) : (

                                <form onSubmit={handleSubmit}>
                                    <div style={{ marginBottom: "15px" }}>
                                        <label
                                            htmlFor="areaatuacao"
                                            style={{ display: "block", marginBottom: "5px" }}
                                        >
                                            {t("calendario.addNewEvent.area")}
                                        </label>

                                        <select
                                            id="areaatuacao"
                                            value={areaatuacaoId}
                                            onChange={(e) => setAreaatuacaoId(e.target.value)}
                                            style={{
                                                width: "100%",
                                                padding: "8px",
                                                borderRadius: "4px",
                                                border: "1px solid #ccc",
                                            }}
                                            required
                                        >
                                            <option value="">
                                                {t("calendario.addNewEvent.areaPlaceholder")}
                                            </option>

                                            {areasDeAtuacao.map((area) => (
                                                <option key={area.id} value={area.id}>
                                                    {area.nome}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    <div style={{ marginBottom: "15px" }}>
                                        <label
                                            htmlFor="descricao"
                                            style={{ display: "block", marginBottom: "5px" }}
                                        >
                                            {t("calendario.addNewEvent.description")}
                                        </label>

                                        <input
                                            type="text"
                                            id="descricao"
                                            value={descricao}
                                            onChange={(e) => setDescricao(e.target.value)}
                                            style={{
                                                width: "100%",
                                                padding: "8px",
                                                borderRadius: "4px",
                                                border: "1px solid #ccc",
                                            }}
                                            required
                                        />
                                    </div>

                                    <div style={{ marginBottom: "15px" }}>
                                        <label
                                            htmlFor="data_compromisso"
                                            style={{ display: "block", marginBottom: "5px" }}
                                        >
                                            {t("calendario.addNewEvent.date")}
                                        </label>

                                        <input
                                            type="date"
                                            id="data_compromisso"
                                            value={dataCompromisso}
                                            onChange={(e) => setDataCompromisso(e.target.value)}
                                            style={{
                                                width: "100%",
                                                padding: "8px",
                                                borderRadius: "4px",
                                                border: "1px solid #ccc",
                                            }}
                                            required
                                        />
                                    </div>

                                    <button
                                        type="submit"
                                        style={{
                                            width: "100%",
                                            padding: "10px",
                                            backgroundColor: "#449A86",
                                            color: "#fff",
                                            border: "none",
                                            borderRadius: "4px",
                                            cursor: "pointer",
                                            fontSize: "16px",
                                        }}
                                    >
                                        {t("calendario.addNewEvent.button")}
                                    </button>
                                </form>
                            )}
                        </div>
                    </div>
                )}

                {/* Modal para Editar e Deletar um Evento */}
                {isEditModalOpen && editModalType === "calendario" && (
                    <div style={modalStyles.overlay}>
                        <div style={modalStyles.content}>
                            <button style={modalStyles.closeBtn} onClick={closeEditModal}>
                                &times;
                            </button>

                            <h2>{t("calendario.editEvent.title")}</h2>

                            {loadingAreas ? (
                                <p>{t("calendario.loadingAreas")}</p>
                            ) : errorAreas ? (
                                <p className="text-danger">{errorAreas}</p>
                            ) : (

                                <form onSubmit={handleEditSubmit}>
                                    <div style={{ marginBottom: "15px" }}>
                                        <label
                                            htmlFor="edit_areaatuacao"
                                            style={{ display: "block", marginBottom: "5px" }}
                                        >
                                            {t("calendario.editEvent.area")}
                                        </label>

                                        <select
                                            id="edit_areaatuacao"
                                            value={editEvent.areaatuacao_id}
                                            onChange={(e) =>
                                                setEditEvent({
                                                    ...editEvent,
                                                    areaatuacao_id: e.target.value,
                                                })
                                            }
                                            style={{
                                                width: "100%",
                                                padding: "8px",
                                                borderRadius: "4px",
                                                border: "1px solid #ccc",
                                            }}
                                            required
                                        >
                                            <option value="">
                                                {t("calendario.editEvent.areaPlaceholder")}
                                            </option>
                                            {areasDeAtuacao.map((area) => (
                                                <option key={area.id} value={area.id}>
                                                    {area.nome}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    <div style={{ marginBottom: "15px" }}>
                                        <label
                                            htmlFor="edit_descricao"
                                            style={{ display: "block", marginBottom: "5px" }}
                                        >
                                            {t("calendario.editEvent.description")}
                                        </label>

                                        <input
                                            type="text"
                                            id="edit_descricao"
                                            value={editEvent.title}
                                            onChange={(e) =>
                                                setEditEvent({
                                                    ...editEvent,
                                                    title: e.target.value,
                                                })
                                            }
                                            style={{
                                                width: "100%",
                                                padding: "8px",
                                                borderRadius: "4px",
                                                border: "1px solid #ccc",
                                            }}
                                            required
                                        />
                                    </div>

                                    <div style={{ marginBottom: "15px" }}>
                                        <label
                                            htmlFor="edit_data_compromisso"
                                            style={{ display: "block", marginBottom: "5px" }}
                                        >
                                            {t("calendario.editEvent.date")}
                                        </label>

                                        <input
                                            type="date"
                                            id="edit_data_compromisso"
                                            value={editEvent && editEvent.start}
                                            onChange={(e) => {
                                                setEditEvent({
                                                    ...editEvent,
                                                    start: e.target.value,
                                                });
                                            }}
                                            style={{
                                                width: "100%",
                                                padding: "8px",
                                                borderRadius: "4px",
                                                border: "1px solid #ccc",
                                            }}
                                            required
                                        />
                                    </div>

                                    <button
                                        type="submit"
                                        style={{
                                            width: "100%",
                                            padding: "10px",
                                            backgroundColor: "#449A86",
                                            color: "#fff",
                                            border: "none",
                                            borderRadius: "4px",
                                            cursor: "pointer",
                                            fontSize: "16px",
                                        }}
                                    >
                                        {t("calendario.editEvent.buttonEdit")}
                                    </button>

                                    <button
                                        type="button"
                                        className="btn btn-light"
                                        style={{
                                            width: "100%",
                                            padding: "10px",
                                            border: "none",
                                            borderRadius: "4px",
                                            cursor: "pointer",
                                            fontSize: "16px",
                                            marginTop: "10px",
                                        }}
                                        onClick={handleDelete}
                                    >
                                        {t("calendario.editEvent.buttonDelete")}
                                    </button>
                                </form>
                            )}
                        </div>
                    </div>
                )}

                {/* Modal com a frase "Deseja ver Informativo Diário"  */}
                {isEditModalOpen && editModalType === "apresentacao" && (
                    <div style={modalStyles.overlay}>
                        <div style={modalStyles.content}>
                            <button style={modalStyles.closeBtn} onClick={closeEditModal}>
                                &times;
                            </button>

                            <h2>{t("calendario.presentationEvent.title")}</h2>

                            <div style={{ margin: "60px 0" }}>{editEvent.title}</div>

                            <Link
                                to={`/documento/apresentacao/${editEvent.id}`}
                                style={{
                                    display: "block",
                                    textAlign: "center",
                                    width: "100%",
                                    padding: "10px",
                                    backgroundColor: "#449A86",
                                    color: "#fff",
                                    border: "none",
                                    borderRadius: "4px",
                                    cursor: "pointer",
                                    fontSize: "16px",
                                }}
                            >
                                {t("calendario.presentationEvent.button")}
                            </Link>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Calendario;
