import React, { createContext, useState, useContext } from 'react';

const StatisticalDataContext = createContext();

export const useStatisticalDataContext = () => {
    return useContext(StatisticalDataContext);
};

export const StatisticalDataProvider = ({ children }) => {

    const currentYear = new Date().getFullYear();
    const currenthMonth = new Date().getMonth() + 1;

    const [errorMonth, setErrorYear] = useState(null);
    const [errorYear, setErrorMonth] = useState(null);
    const [loadingMonth, setLoadingMonth] = useState(true);
    const [loadingYear, setLoadingYear] = useState(true);
    const [annualFilter, setAnnualFilter] = useState(currentYear);
    const [yearFilter, setYearFilter] = useState(currentYear);
    const [monthFilter, setMonthFilter] = useState(currenthMonth);
    const [annualData, setAnnualData] = useState([]);
    const [monthlyData, setMonthlyData] = useState([]);
    const [filterDataToHeatMap, setFilterDataToHeatMap] = useState([]);

    return (
        <StatisticalDataContext.Provider value={
            {
                errorMonth, setErrorYear,
                errorYear, setErrorMonth,
                loadingMonth, setLoadingMonth,
                loadingYear, setLoadingYear,
                annualFilter, setAnnualFilter,
                yearFilter, setYearFilter,
                monthFilter, setMonthFilter,
                annualData, setAnnualData,
                monthlyData, setMonthlyData,
                filterDataToHeatMap, setFilterDataToHeatMap
            }
        }>
            {children}
        </StatisticalDataContext.Provider>
    );
};
