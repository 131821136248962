import React, { createContext, useState, useContext } from 'react';

const CalendarioContext = createContext();

export const useCalendarioContext = () => {
    return useContext(CalendarioContext);
};

export const CalendarioProvider = ({ children }) => {

    const [year, setYear] = useState(new Date().getFullYear());
    const [month, setMonth] = useState(new Date().getMonth());
    const [eventos, setEventos] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [areaatuacaoId, setAreaatuacaoId] = useState("");
    const [descricao, setDescricao] = useState("");
    const [dataCompromisso, setDataCompromisso] = useState("");
    const [areasDeAtuacao, setAreasDeAtuacao] = useState([]);
    const [loadingAreas, setLoadingAreas] = useState(false);
    const [errorAreas, setErrorAreas] = useState(null);
    const [editEvent, setEditEvent] = useState(null);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editModalType, setEditModalType] = useState(null);

    return (
        <CalendarioContext.Provider value={
            {
                year, setYear,
                month, setMonth,
                eventos, setEventos,
                loading, setLoading,
                error, setError,
                isModalOpen, setIsModalOpen,
                areaatuacaoId, setAreaatuacaoId,
                descricao, setDescricao,
                dataCompromisso, setDataCompromisso,
                areasDeAtuacao, setAreasDeAtuacao,
                loadingAreas, setLoadingAreas,
                errorAreas, setErrorAreas,
                editEvent, setEditEvent,
                isEditModalOpen, setIsEditModalOpen,
                editModalType, setEditModalType
            }
        }>
            {children}
        </CalendarioContext.Provider>
    );
};
