import { Route, HashRouter as Router, Routes, useParams } from "react-router-dom";

import { InternalScreen } from "./components/Interna/InternalScreen";

import Apresentacoes from "./pages/ApresentacoesMensais";
import Biblioteca from "./pages/Biblioteca";
import Calendario from "./pages/Calendario";
import Profile from "./pages/Conta";
import Dashboard from "./pages/Dashboard";
import Informativos from "./pages/Informativos";
import Leituras from "./pages/LeiturasTecnicas";
import Login from "./pages/Login";
import ResetPassword from "./pages/ResetPassword";
import StatisticalData from "./pages/StatisticalData";

import { ApresentacoesMensaisProvider } from "./context/ApresentacoesMensaisContext";
import { BibliotecaProvider } from "./context/BibliotecaContext";
import { CalendarioProvider } from "./context/CalendarioContext";
import { ContaProvider } from "./context/ContaContext";
import { DashboardProvider } from "./context/DashboardContext";
import { InformativoProvider } from "./context/InformativoContext";
import { LeisTraduzidasProvider } from "./context/LeisTraduzidasContext";
import { LeiturasTecnicasProvider } from "./context/LeiturasTecnicasContext";
import { LoginProvider } from "./context/LoginContext";
import { ResetPasswordProvider } from "./context/ResetPasswordContext";
import { StatisticalDataProvider } from "./context/StatisticalDataContext";
import Trial from "./pages/Trial";

const TrialWithParams = () => {
    const { encryptedData } = useParams();
    return <Trial encryptedData={encryptedData} />;
};

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/login" element={
                    <LoginProvider>
                        <Login />
                    </LoginProvider>
                } />

                <Route exact path="/" element={
                    <LoginProvider>
                        <Login />
                    </LoginProvider>
                } />

                <Route path="/reset/password" element={
                    <ResetPasswordProvider>
                        <ResetPassword />
                    </ResetPasswordProvider>
                } />

                <Route path="/dashboard" element={
                    <DashboardProvider>
                        <Dashboard />
                    </DashboardProvider>
                } />

                <Route path="/biblioteca" element={
                    <BibliotecaProvider>
                        <Biblioteca />
                    </BibliotecaProvider>
                } />

                <Route path="/calendario" element={
                    <CalendarioProvider>
                        <Calendario />
                    </CalendarioProvider>
                } />

                <Route path="/data" element={
                    <StatisticalDataProvider>
                        <StatisticalData />
                    </StatisticalDataProvider>
                } />

                <Route path="/apresentacoes" element={
                    <ApresentacoesMensaisProvider>
                        <Apresentacoes />
                    </ApresentacoesMensaisProvider>
                } />

                <Route path="/informativos" element={
                    <InformativoProvider>
                        <Informativos />
                    </InformativoProvider>
                } />

                {/* Entrará em uma versão futura */}
                {/* <Route path="/leis" element={
                    <LeisTraduzidasProvider>
                        <LeisTraduzidas />
                    </LeisTraduzidasProvider>
                } /> */}

                <Route path="/leituras" element={
                    <LeiturasTecnicasProvider>
                        <Leituras />
                    </LeiturasTecnicasProvider>
                } />

                <Route path="/profile" element={
                    <ContaProvider>
                        <Profile />
                    </ContaProvider>
                } />

                <Route path="/trial/:encryptedData" element={
                    <TrialWithParams />
                } />

                <Route path="/documento/:path/:id" element={<InternalScreen />} />
            </Routes>
        </Router>
    );
}

export default App;
