import md5 from "js-md5";

import { useEffect } from "react";

import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { BsDownload } from "react-icons/bs";
import { LuArrowRight } from "react-icons/lu";
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";

import { apiRequest } from "../api/apiRequest";

import Loading from "../components/Loading";
import Navbar from "../components/Navbar";
import SideMenu from "../components/SideMenu";

import { useInformativoContext } from "../context/InformativoContext";

function Informativos() {
    const { t } = useTranslation();

    const {
        dataDe, setDataDe,
        dataAte, setDataAte,
        data, setData,
        loading, setLoading,
        error, setError,
        page, setPage,
        itemsPerPage, setItemsPerPage,
        hasMore, setHasMore
    } = useInformativoContext();

    const startItem = page * itemsPerPage + 1;
    const endItem = startItem + data.length - 1;

    const pagination = "&pagina=" + page;
    const dateFrom = "&data_de=" + dataDe;
    const dateTo = "&data_ate=" + dataAte;

    const fetchData = async () => {
        setLoading(true);
        setError(null);
        setHasMore(true);

        try {
            const response = await apiRequest(
                "GET",
                `/apresentacoes?limite_quantidade=${itemsPerPage}${pagination}${dateFrom}${dateTo}&tabela_nome=apresentacao`
            );

            const fetchedData = response.data.newsletters || [];

            setData(fetchedData);
            setHasMore(fetchedData.length === itemsPerPage);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
            setError(null);
        }
    };

    useEffect(() => {
        fetchData();
    }, [page, itemsPerPage]);

    const getHash = (id) => {
        return md5(id);
    };

    return (
        <div id="wrapper" style={{ backgroundColor: "#043A5E" }}>
            <SideMenu />

            <div id="page-wrapper" style={{ backgroundColor: "#FCFCFD" }}>
                <Navbar />

                <div
                    className="pl-2 mt-4 mb-4 custom-normal"
                    style={{ fontSize: "38px", fontWeight: "600" }}
                >
                    {t("informativos.title")}
                </div>

                <div
                    className="bg-white shadow-sm d-flex flex-column justify-content-between"
                    style={{ borderRadius: "1rem" }}
                >
                    {/* Filtro */}
                    <div className="form mx-3 my-3">
                        <h3 className="control-label mb-3"> {t("informativos.filter")}</h3>

                        <div className="d-flex" style={{ gap: "10px" }}>
                            <div className="d-flex align-items-center">
                                {t("informativos.dateFrom")}:
                            </div>

                            <div style={{ position: "relative" }}>
                                <input
                                    type="date"
                                    value={dataDe}
                                    onChange={(e) => setDataDe(e.target.value)}
                                    style={{
                                        width: "100%",
                                        padding: "13px",
                                        paddingRight: "13px",
                                        border: "1px solid #E5E7EB",
                                        borderRadius: "7px",
                                        fontSize: "14px",
                                        color: "#6C737F",
                                    }}
                                />
                            </div>

                            <div className="d-flex align-items-center">
                                {t("informativos.dateTo")}:
                            </div>

                            <div style={{ position: "relative" }}>
                                <input
                                    type="date"
                                    value={dataAte}
                                    onChange={(e) => setDataAte(e.target.value)}
                                    style={{
                                        width: "100%",
                                        padding: "13px",
                                        paddingRight: "13px",
                                        border: "1px solid #E5E7EB",
                                        borderRadius: "7px",
                                        fontSize: "14px",
                                        color: "#6C737F",
                                    }}
                                />
                            </div>

                            <div className="d-flex align-items-center">
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    style={{ height: "35px" }}
                                    onClick={() => fetchData()}
                                >
                                    {t("informativos.toFilter")}
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="content" style={{ marginTop: "20px" }}>
                        {loading && <Loading />}

                        {error && <p className="mx-3">{t("informativos.errorMessage")}</p>}

                        {!loading && (
                            <div>
                                <div className="mb-3">
                                    <div
                                        className="d-flex justify-content-between py-3 px-3 bg-light row"
                                        style={{ fontSize: "13px", fontWeight: "600" }}
                                    >
                                        <div className="col-10">{t("informativos.reports")}</div>

                                        <div className="col-2">{t("informativos.actions")}</div>
                                    </div>

                                    {data && data.length > 0
                                        ? data.map((item) => (
                                            <div
                                                className="d-flex py-3 px-3 row"
                                                key={item.id}
                                                style={{
                                                    fontSize: "13px",
                                                    fontWeight: "600",
                                                    borderBottom: "1px solid #F3F4F7",
                                                }}
                                            >
                                                <div className="d-flex align-items-center col-10">
                                                    <div
                                                        className="mr-3 d-flex flex-column justify-content-center align-items-center"
                                                        style={{
                                                            width: "60px",
                                                            height: "60px",
                                                            borderRadius: "15px",
                                                            backgroundColor: "#E5E7EB",
                                                        }}
                                                    >
                                                        <div
                                                            style={{ fontSize: "14px", fontWeight: "600" }}
                                                        >
                                                            {new Date(item.criado)
                                                                .toLocaleString("default", { month: "short" })
                                                                .toUpperCase()}
                                                        </div>

                                                        <div
                                                            style={{ fontSize: "15px", fontWeight: "700" }}
                                                        >
                                                            {new Date(item.enviado).getDate()}
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <div>{item.assunto}</div>
                                                    </div>
                                                </div>

                                                <div
                                                    className="d-flex align-items-center col-2"
                                                    style={{ fontSize: "22px" }}
                                                >
                                                    <a
                                                        className="mr-3"
                                                        style={{ cursor: "pointer", color: "#232730" }}
                                                        href={`https://www.taxupdate.co/newsletter/pdf/${item.id
                                                            }/${getHash(item.criado)}`}
                                                        target="_blank"
                                                    >
                                                        <BsDownload />
                                                    </a>

                                                    <Link
                                                        to={`/documento/apresentacao/${item.id}`}
                                                        style={{ color: "#232730" }}
                                                    >
                                                        <LuArrowRight />
                                                    </Link>
                                                </div>
                                            </div>
                                        ))
                                        : !loading &&

                                        !error && (
                                            <div style={{ textAlign: "center", padding: "20px" }}>
                                                {t("informativos.noData")}
                                            </div>
                                        )}

                                    {/* Paginação */}
                                    <div className="d-flex justify-content-end align-items-center py-2">
                                        <div className="mr-4">
                                            {" "}

                                            {t("informativos.documentsPerPage")}
                                        </div>

                                        <select
                                            className="mr-4"
                                            style={{ border: "none" }}
                                            value={itemsPerPage}
                                            onChange={(e) => {
                                                setItemsPerPage(parseInt(e.target.value));
                                                setPage(0);
                                            }}
                                        >
                                            <option value="5">5</option>
                                            <option value="10">10</option>
                                            <option value="20">20</option>
                                        </select>

                                        <div className="mr-4">
                                            {t("informativos.showing")} {startItem}-{endItem}
                                        </div>

                                        <div
                                            className="mr-4"
                                            style={{
                                                fontSize: "20px",
                                                cursor: page === 0 ? "default" : "pointer",
                                                opacity: page === 0 ? 0.5 : 1,
                                            }}
                                            onClick={() => {
                                                if (page > 0) {
                                                    setPage(page - 1);
                                                }
                                            }}
                                        >
                                            <RiArrowLeftSLine />
                                        </div>

                                        <div
                                            className="mr-4"
                                            style={{
                                                fontSize: "20px",
                                                cursor: hasMore ? "pointer" : "default",
                                                opacity: hasMore ? 1 : 0.5,
                                            }}
                                            onClick={() => {
                                                if (hasMore) {
                                                    setPage(page + 1);
                                                }
                                            }}
                                        >
                                            <RiArrowRightSLine />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Informativos;
