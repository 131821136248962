import React from "react";

import { useTranslation } from "react-i18next";

import city from "../../assets/img/dashboard/city.png";

function Banner() {
    const { t } = useTranslation();

    return (
        <div className="">
            <div
                className="border-0 shadow-sm w-100 px-5 pb-4 d-flex flex-column align-items-start"
                style={{
                    borderRadius: "1rem",
                    backgroundColor: "#205070",
                    backgroundImage: `url(${city})`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                }}
            >
                <h2
                    className="text-warning"
                    style={{ fontSize: "24px", fontWeight: "600" }}
                >
                    {t("dashboard.bannerTitle")}
                </h2>

                <div
                    className="text-white"
                    style={{
                        fontSize: "12px",
                        fontWeight: "400",
                        marginBottom: "12px",
                    }}
                >
                    {t("dashboard.bannerDescription")}
                </div>

                <div className="w-100 d-flex justify-content-start">
                    <a
                        href=" https://taxupdate.com.br/taxupdate-latam-monitoramento-simultaneo-de-legislacoes/"
                        className="btn mt-3 px-5 py-2"
                        style={{
                            backgroundColor: "#F3C70F",
                            borderRadius: "0.5rem",
                            fontWeight: "600",
                            color: "#043A5E",
                        }}
                    >
                        {t("dashboard.bannerButton")}
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Banner;
